import { useEffect } from 'react';
import { useRouter } from 'next/router';

const useScrollToHash = (): void => {
  const router = useRouter();

  useEffect(() => {
    const hash = router.asPath.split('#')[1];
    if (!hash) return;

    const elementId = hash;
    let timeOutId: NodeJS.Timeout;
    let elapsedTime = 0;

    const checkAndScroll = (): void => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        return;
      }
      elapsedTime += 50;
      if (elapsedTime >= 2000) {
        clearTimeout(timeOutId);
        return;
      }
      timeOutId = setTimeout(checkAndScroll, 50);
    };

    checkAndScroll();

    return (): void => {
      if (timeOutId) clearTimeout(timeOutId);
    };
  }, [router.asPath]);
};

export default useScrollToHash;
