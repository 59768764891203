import { Field, RichTextField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type SolutionCalloutBlockProps = ComponentProps & {
  fields: {
    title: Field<string>;
    description: RichTextField;
  };
};

const SolutionCalloutBlock = (props: SolutionCalloutBlockProps): JSX.Element => {
  return (
    <section className="py-5 border-b border-neutral-light last:border-none last:pb-0 first:pt-0 ">
      <Text
        tag="div"
        className="text-left uppercase text-lg font-bold tracking-average text-almost-black"
        field={props?.fields?.title}
      />
      <RichText
        className="rte text-left font-normal text-base [&>a]:underline [&>a]:text-primary text-almost-black"
        field={props?.fields?.description}
        tag="div"
      />
    </section>
  );
};

export default withDatasourceCheck()<SolutionCalloutBlockProps>(SolutionCalloutBlock);
