import {
  Field,
  ImageField,
  LinkField,
  RichText,
  Text,
  useSitecoreContext,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import { default as Link } from 'components/foundation/non-sitecore/NextLink';
import { ComponentProps } from 'lib/component-props';
import { t14TemplateIdVal, resourceCenterPageTemplateId } from 'lib/constants';

type AuthorBlockProps = ComponentProps & {
  fields: {
    image: ImageField;
    authorName: Field<string>;
    jobTitle: Field<string>;
    websiteLink: LinkField;
    description: Field<string>;
    noFollow?: Field<boolean>;
  };
};

const AuthorBlock = (props: AuthorBlockProps): JSX.Element => {
  const context = useSitecoreContext();
  const t14TemplateId = t14TemplateIdVal;
  const pageTemplateId = context?.sitecoreContext.route?.templateId;
  const addWidthforT14 =
    t14TemplateId === pageTemplateId || resourceCenterPageTemplateId === pageTemplateId
      ? 'lg:max-w-screen-xl 2xl:px-6'
      : '';
  const authors = context.sitecoreContext.route?.fields
    ?.author as unknown as AuthorBlockProps | null;
  const authorBlock: AuthorBlockProps = authors ? { ...authors } : { ...props };
  return (
    <>
      {authorBlock && (
        <li
          className={`my-0 flex flex-col space-y-6 px-6 md:space-y-0 md:px-0 md:grid md:avid-grid-layout ${addWidthforT14}`}
        >
          <NextImage
            field={authorBlock.fields?.image}
            className="aspect-square rounded md:col-span-3 lg:col-span-2 lg:col-start-2 xl:col-start-3"
          />
          <div className="md:col-span-9 lg:col-span-8 xl:col-span-6">
            <Text
              tag="h3"
              field={authorBlock.fields?.authorName}
              className="featured-user-name uppercase tracking-[5.04px]"
            />
            <Text
              tag="p"
              field={authorBlock.fields?.jobTitle}
              className="featured-user-title uppercase tracking-[4px]"
            />
            <Link
              field={authorBlock.fields?.websiteLink}
              className="btn-link p-0 text-base hover:bg-transparent"
              linkCustomizations={props.fields}
            />
            <RichText tag="p" field={authorBlock.fields?.description} className="rte body mt-4" />
          </div>
        </li>
      )}
    </>
  );
};

export default withDatasourceCheck()<AuthorBlockProps>(AuthorBlock);
