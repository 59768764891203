import { getCartRecommendationsListController } from 'components/coveo-recommendations/coveo-core/controllers-coveo-recommendations';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect, useMemo, useState } from 'react';
import {
  CoveoRecommendationsProps,
  CoveoRecommendedProductDetails,
  mapRecommendationsToProducts,
} from 'components/coveo-recommendations/coveo-recommendations';
import { useAppSelector } from 'lib/store/hooks';
import CoveoRecommendationsCarousel from 'components/coveo-recommendations/CoveoRecommendationsCarousel';
import { useCartData } from 'components/cart/cart-hooks';

const CoveoCartRecommendations: React.FC<CoveoRecommendationsProps> = (props) => {
  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode) ?? 'USD';
  const controller = useMemo(
    () => getCartRecommendationsListController(props?.fields?.maxResultsToShow?.value ?? 3),
    [props?.fields?.maxResultsToShow?.value]
  );
  const cartItems = useCartData()?.cartItems;
  const [controllerState, setCartRecommendState] = useState(controller.state);
  const [productsArray, setProductsArray] = useState<CoveoRecommendedProductDetails[] | null>(null);

  useEffect(() => {
    const productIDs = cartItems?.map((item) => item.cartItem.productId);
    if (!productIDs || productIDs.length < 1) {
      return;
    }
    const unsubscribe = controller.subscribe(() => {
      setCartRecommendState(controller.state);
    });
    controller.setSkus(productIDs);
    controller.refresh();
    return () => unsubscribe(); // Cleanup the subscription when the component unmounts
  }, [cartItems, controller]);

  useEffect(() => {
    if (productsArray?.length && productsArray?.length > 0 && cartItems?.length === 0) {
      setProductsArray(null);
      return;
    }
  }, [cartItems, productsArray?.length]);

  useEffect(() => {
    if (!currencyIsoCode) {
      return;
    }
    setProductsArray(
      mapRecommendationsToProducts(controllerState.recommendations, currencyIsoCode)
    );
  }, [controllerState.recommendations, currencyIsoCode]);

  if (controllerState.error) {
    return null;
  }

  return (
    <div id="coveo-cart-recommendations-wrapper">
      {productsArray && productsArray.length > 0 && (
        <CoveoRecommendationsCarousel
          title={props?.fields?.title}
          description={props?.fields?.description}
          fetchPriceFromSalesforce={props?.fields?.fetchPriceFromSalesforce}
          productsArray={productsArray}
        />
      )}
    </div>
  );
};

export default withDatasourceCheck()<CoveoRecommendationsProps>(CoveoCartRecommendations);
