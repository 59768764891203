import {
  Field,
  LinkField,
  RichText,
  isEditorActive,
  withPlaceholder,
} from '@sitecore-jss/sitecore-jss-nextjs';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import { BaseCarouselProps } from 'components/carousel/BaseCarouselProps';
import Carousel from 'components/carousel/Carousel';
import { useCartData } from 'components/cart/cart-hooks';
import { debounce } from 'lib/commerce/common/debounce';
import { ComponentProps } from 'lib/component-props';
import {
  AnalyticsProductItem,
  pushSelectItem,
  pushViewItemList,
  pushViewItems,
} from 'lib/google-analytics/commerce';
import { useAppSelector } from 'lib/store/hooks';
import React, { ReactElement } from 'react';

type ProductListCarouselProps = ComponentProps &
  BaseCarouselProps & {
    fields: {
      heading: Field<string>;
      link: LinkField;
      noFollow?: Field<boolean>;
    };
    name: string;
    productListCarouselItems: JSX.Element[];
  };

const ProductListCarousel = (props: ProductListCarouselProps): JSX.Element => {
  const editorActive = isEditorActive();
  const hasLink = props.fields?.link?.value != null;
  const largeVariant =
    props.params?.MaxItemsPerSlideOnDesktop != null &&
    props.params?.MaxItemsPerSlideOnDesktop == '4';

  let analyticsProducts: AnalyticsProductItem[] = [];
  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode);
  const cart = useCartData();

  const itemListName = 'Product List Carousel';
  const itemListId = `ProductListCarousel_${props.rendering.uid}`;
  const customerInfo = useAppSelector((state) => state?.customer?.customerData);
  const pushList = (): void => {
    if (currencyIsoCode == null || cart?.cartSummary == null) {
      return;
    }

    pushViewItemList(
      itemListId,
      itemListName,
      currencyIsoCode,
      analyticsProducts,
      (customerInfo?.contactId as string) || ''
    );
    analyticsProducts = [];
  };

  const debouncedPushList = debounce(pushList, 200);

  const onCardPriceCallback = (analyticsProduct: AnalyticsProductItem): void => {
    analyticsProducts.push(analyticsProduct);
    debouncedPushList();
  };

  const onSelectItemCallback = (analyticsProduct: AnalyticsProductItem): void => {
    if (currencyIsoCode == null || cart?.cartSummary == null) {
      return;
    }

    pushViewItems(
      currencyIsoCode,
      cart.cartSummary.totalProductAmountAfterAdjustments,
      [analyticsProduct],
      (customerInfo?.contactId as string) || ''
    );
    pushSelectItem(
      itemListId,
      itemListName,
      currencyIsoCode,
      [analyticsProduct],
      (customerInfo?.contactId as string) || ''
    );
  };

  return (
    <div className="my-10 xl:my-20">
      <RichText
        className="rte text-center h2 uppercase tracking-[0.28rem] mb-8"
        field={props.fields?.heading}
        tag="h2"
      />
      <div className="max-w-xl xl:max-w-content mx-3 sm:mx-auto">
        <div
          className={
            editorActive
              ? `flex flex-wrap ${
                  largeVariant
                    ? '[&>.carousel-item]:w-1/4 [&>.carousel-item]:max-w-1/4'
                    : '[&>.carousel-item]:w-1/6 [&>.carousel-item]:max-w-1/6'
                } `
              : 'hidden'
          }
        >
          {props.productListCarouselItems}
        </div>

        {!editorActive && props.params != null && (
          <Carousel
            MaxItemsPerSlideOnDesktop={props.params.MaxItemsPerSlideOnDesktop}
            MaxItemsPerSlideOnTablet={props.params.MaxItemsPerSlideOnTablet}
            MaxItemsPerSlideOnMobile={props.params.MaxItemsPerSlideOnMobile}
            desktopSize={1199}
          >
            {props.productListCarouselItems.map((component, index): JSX.Element => {
              return React.cloneElement(component as ReactElement, {
                fromCloneListId: itemListId,
                fromCloneListName: itemListName,
                fromCloneIndex: index,
                fromCloneOnPriceCallback: onCardPriceCallback,
                fromCloneOnSelectItemCallback: onSelectItemCallback,
              });
            })}
          </Carousel>
        )}
        {(editorActive || hasLink) && (
          <div className="text-center mt-12 flex justify-center">
            <NextLink
              className="btn-outline"
              field={props.fields?.link}
              linkCustomizations={props.fields}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default withPlaceholder({
  placeholder: 'product-list-carousel-items',
  prop: 'productListCarouselItems',
})(ProductListCarousel);
