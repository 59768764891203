import gql from 'graphql-tag';

const getMultiTierPricingTableQuery = gql`
  # Write your query or mutation here
  query MultiTierPricingTableQuery($datasource: String!, $language: String!) {
    datasource: item(path: $datasource, language: $language) {
      id
      name
      ... on MultiTierProductTable {
        productTableId {
          jsonValue
        }
        cards {
          targetIds
        }
        defaultCardForMobile {
          targetIds
        }
        footerContent {
          jsonValue
        }
        link {
          jsonValue
        }
        badCombinationMessage {
          jsonValue
        }
      }
      ... on _FrequencyTypeDropdown {
        defaultFrequencyType {
          targetItems {
            ... on DropdownOption {
              title {
                jsonValue
              }
              value {
                jsonValue
              }
            }
          }
        }
        frequencyTypeOptions {
          targetItems {
            ... on DropdownOption {
              title {
                jsonValue
              }
              value {
                jsonValue
              }
            }
          }
        }
      }
      ... on _UserTypeDropdown {
        defaultUserType {
          targetItems {
            ... on DropdownOption {
              title {
                jsonValue
              }
              value {
                jsonValue
              }
            }
          }
        }
        userTypeOptions {
          targetItems {
            ... on DropdownOption {
              title {
                jsonValue
              }
              value {
                jsonValue
              }
            }
          }
        }
      }
    }
  }
`;

export default getMultiTierPricingTableQuery;
