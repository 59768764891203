import { Field, ImageField, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import ResultImage from 'components/atomic-components/ResultImage';
import { ComponentProps } from 'lib/component-props';

type PressReleaseProps = ComponentProps & {
  fields: {
    data: {
      contextItem: {
        contacts: {
          targetItems: [
            {
              field: Field<string>;
            },
          ];
        };
        copyrightText: {
          targetItems: [
            {
              field: Field<string>;
            },
          ];
        };
        searchTitle: Field<string>;
        navigationTitle: Field<string>;
        location: Field<string>;
        summary: Field<string>;
        contentAboveImage: Field<string>;
        featuredImageUrl: Field<string>;
        featuredImage: {
          jsonValue: ImageField;
        };
        content: Field<string>;
        contactsHeading: Field<string>;
        copyrightHeading: Field<string>;
        pressReleaseDate: {
          formattedDateValue: string;
        };
      };
    };
  };
};

const PressRelease = ({ fields }: PressReleaseProps): JSX.Element => {
  const item = fields.data?.contextItem;
  const today = new Date();
  const year = today.getFullYear();

  const imageUrl =
    item?.featuredImageUrl?.value || item?.featuredImage?.jsonValue?.value?.src || '';
  const altText = item?.searchTitle?.value || '';
  const publishDate = item?.pressReleaseDate?.formattedDateValue
    ? new Date(item.pressReleaseDate.formattedDateValue)
    : null;

  const renderLocationAndDate = Boolean(item?.location?.value?.trim() && publishDate);
  const renderCopyright = Boolean(item?.copyrightText?.targetItems?.length);
  const renderContacts = Boolean(item?.contacts?.targetItems?.length);

  return (
    <div className="w-full max-w-screen-2xl mx-auto py-4 text-base-darker text-left align-top leading-[1.875rem] mb-3 lg:text-2xl lg:leading-9">
      <div className="max-w-screen-xl px-6 py-12 mx-6 xl:mx-auto">
        {renderLocationAndDate && (
          <>
            <Text tag="h1" field={item.searchTitle} className="text-32 leading-37" />
            <p className="py-4 font-bold text-base">
              <Text tag="span" field={item.location} />, {publishDate?.toLocaleDateString()}
            </p>
          </>
        )}

        {item?.summary?.value?.trim() && (
          <Text
            tag="h2"
            field={item.summary}
            className="rte italic text-center py-4 text-base normal-case"
          />
        )}

        {item?.contentAboveImage?.value?.trim() && (
          <RichText field={item.contentAboveImage} className="rte py-4 text-base" />
        )}

        {imageUrl && (
          <ResultImage
            src={imageUrl}
            alt={altText}
            className="max-w-full m-auto h-auto col-span-12 lg:col-span-8 xl:col-span-7 mb-4 py-4"
          />
        )}

        <RichText
          field={item?.content}
          className="rte body text-base-darker text-left richTextWithLinks text-base"
        />

        {renderCopyright && (
          <>
            <Text tag="h3" field={item.copyrightHeading} className="pt-6 text-xl" />
            {item.copyrightText.targetItems.map((copyright, index) =>
              copyright?.field?.value?.trim() ? (
                <div className="richTextWithLinks py-2 text-base" key={index}>
                  <RichText
                    field={{
                      ...copyright.field,
                      value: copyright.field.value.replace('[#year#]', year.toString()),
                    }}
                    tag="div"
                    className="rte body text-base-darker text-left richTextWithLinks text-base"
                  />
                </div>
              ) : null
            )}
          </>
        )}

        {renderContacts && (
          <>
            <Text tag="h3" field={item.contactsHeading} className="pt-6 text-xl" />
            {item.contacts.targetItems.map((contact, index) =>
              contact?.field?.value?.trim() ? (
                <div className="richTextWithLinks lg py-2 text-base" key={index}>
                  <RichText
                    field={contact.field}
                    tag="div"
                    className="rte body text-base-darker text-left richTextWithLinks text-base"
                  />
                </div>
              ) : null
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PressRelease;
