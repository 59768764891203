import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { engage, loadEngage } from 'lib/personalize/load-engage';
import { sendPageViewEvent } from 'lib/personalize/page-view-event';

const useEngage = (): void => {
  const router = useRouter();

  useEffect(() => {
    const initializeEngage = async (): Promise<void> => {
      try {
        await loadEngage(); // Ensure Engage is loaded
        if (engage) {
          sendPageViewEvent();
        } else {
          console.error('Engage is still not initialized');
        }
      } catch (error) {
        console.error('Error initializing Engage:', error);
      }
    };
    const sitecorePersonalizations = document.querySelectorAll('[id^=pers-]');
    sitecorePersonalizations.forEach((personalization) => {
      document.querySelector(`style#${personalization.id.replace('pers-', 'style-')}`)?.remove();
      personalization.remove();
    });
    initializeEngage();
  }, [router.asPath]);
};

export default useEngage;
