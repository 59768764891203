import React from 'react';
import { Field, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import Spinner from 'components/spinner/Spinner';
import { useCartData } from 'components/cart/cart-hooks';
import { addCartItem, deleteCartItem } from 'components/cart/cart-slice';
import { CartItem, GetCartItemsData } from 'components/cart/cart-models';
import { ComponentProps } from 'lib/component-props';
import {
  AnalyticsProductItem,
  getProductCartIndex,
  pushAddToCart,
  pushRemoveFromCart,
  pushSelectPromotion,
  pushViewPromotion,
} from 'lib/google-analytics/commerce';
import { PromotionData } from 'lib/promotions/models/promotion-models';
import { getLimitedTimeOffers } from 'lib/promotions/promotion-slice';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { useI18n } from 'next-localization';
import Image from 'next/image';
import { useEffect, useMemo } from 'react';
import { pushCoveoAddCartItem, pushCoveoRemoveCartItem } from 'lib/google-analytics/commerce';
import { getProductDetails } from 'lib/commerce/product/product-slice';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';

export const organizationId =
  process.env.COVEO_ORGANIZATION && process.env.COVEO_ORGANIZATION.length > 0
    ? process.env.COVEO_ORGANIZATION
    : '';

type LimitedTimeOfferProps = ComponentProps & {
  fields: {
    title: Field<string>;
  };
};

const LimitedTimeOffer = (props: LimitedTimeOfferProps): JSX.Element => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();

  const cartState = useAppSelector((state) => state.cart);
  const promotionsState = useAppSelector((state) => state.promotions);
  const { limitedTimeOffers: promotions, status } = promotionsState;
  const { cartAddItemStatus, cartItemsData } = cartState;
  const firstPromotion = promotions.length > 0 ? promotions[0] : null;
  const currencyIsoCode = cartState?.currencyIsoCode;
  const cart = useCartData();
  const { data: session, status: sessionStatus } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);

  const productInCart = useMemo(() => {
    return cartItemsData?.cartItems?.find(
      (c) => c.cartItem.productId === firstPromotion?.productDetails?.productId
    );
  }, [cartItemsData?.cartItems, firstPromotion]);

  const promotionName = 'Limited Time Offer';
  const promotionLocation = 'Cart';
  const customerInfo = useAppSelector((state) => state?.customer?.customerData);

  const onClickAddToCartHandler = (sku: string): void => {
    dispatch(addCartItem({ sku: sku, quantity: 1, bearerToken: bearerToken }));
    if (firstPromotion == null || currencyIsoCode == null || cartState.cartSummaryData == null) {
      return;
    }
    const analyticsItem = generateAnalyticsProductItem(firstPromotion, cart);

    pushAddToCart(
      currencyIsoCode,
      (
        parseFloat(cartState.cartSummaryData.totalProductAmountAfterAdjustments) +
        parseFloat(firstPromotion.discountPrice)
      ).toString(),
      analyticsItem
    );

    pushSelectPromotion(
      promotionName,
      promotionLocation,
      `LimitedTimeOffer_${props.rendering.uid}`,
      firstPromotion.displayName,
      analyticsItem
    );
    onClickSendCoveoAdd(sku);
  };

  const onClickInCartHandler = (): void => {
    if (productInCart) {
      dispatch(
        deleteCartItem({ cartItemId: productInCart.cartItem.cartItemId, bearerToken: bearerToken })
      );

      if (firstPromotion == null || currencyIsoCode == null || cartState.cartSummaryData == null) {
        return;
      }

      pushRemoveFromCart(
        currencyIsoCode,
        parseFloat(firstPromotion.discountPrice).toString(),
        generateAnalyticsProductItem(firstPromotion, cart),
        (customerInfo?.contactId as string) || ''
      );
      onClickSendCoveoRemove(productInCart, currencyIsoCode);
    }
  };

  const onClickSendCoveoAdd = async (sku: string): Promise<void> => {
    if (firstPromotion == null) {
      return;
    }
    const productId = firstPromotion?.productDetails?.productId;
    const productPrice = firstPromotion.productDetails.price;
    const productName = firstPromotion.displayName;
    const productCategory = await getProductCategory(productId);
    pushCoveoAddCartItem(
      sku,
      productName,
      productPrice,
      firstPromotion.productDetails.currencyIsoCode,
      productCategory
    );
  };

  const onClickSendCoveoRemove = async (
    productInCart: CartItem,
    currencyIsoCode: string
  ): Promise<void> => {
    const productCategory = await getProductCategory(productInCart.cartItem.productId);
    pushCoveoRemoveCartItem(
      productInCart.cartItem.productId,
      productInCart.cartItem.name,
      parseFloat(productInCart.cartItem.listPrice),
      currencyIsoCode,
      productCategory,
      productInCart.cartItem.quantity
    );
  };

  const getProductCategory = async (productId: string): Promise<string> => {
    const productDetails = await dispatch(getProductDetails(productId)).unwrap();
    if (productDetails == null) return '';
    const categoryPaths = productDetails.data?.productCategoryPaths?.primary;
    return categoryPaths != null ? categoryPaths[categoryPaths.length - 1]?.name : '';
  };

  useEffect(() => {
    if (sessionStatus === 'loading') {
      return;
    }
    if (
      currencyIsoCode !== null &&
      promotionsState.status === 'idle' &&
      cart?.cartSummary?.cartId
    ) {
      const data = { currencyIsoCode, cartId: cart.cartSummary.cartId, bearerToken: bearerToken };
      dispatch(getLimitedTimeOffers(data));
    }
  }, [
    dispatch,
    currencyIsoCode,
    promotionsState,
    cart?.cartSummary.cartId,
    bearerToken,
    sessionStatus,
  ]);

  useEffect(() => {
    if (firstPromotion == null) {
      return;
    }

    const analyticsItems = generateAnalyticsProductItem(firstPromotion);

    pushViewPromotion(
      promotionName,
      promotionLocation,
      `LimitedTimeOffer_${props.rendering.uid}`,
      firstPromotion.displayName,
      analyticsItems,
      (customerInfo?.contactId as string) || ''
    );
  }, [customerInfo?.contactId, firstPromotion, props.rendering.uid]);

  const generateAnalyticsProductItem = (
    promotion: PromotionData,
    cartData?: GetCartItemsData | null
  ): AnalyticsProductItem => {
    const index = cartData ? getProductCartIndex(promotion.productDetails.productId, cartData) : 0;
    return {
      id: promotion.productDetails.productId,
      sku: promotion.productDetails.sku,
      price: promotion.productDetails.price.toString(),
      discount: (promotion.productDetails.price - parseInt(promotion.discountPrice)).toString(),
      brand: promotion.productDetails.brand,
      name: promotion.productDetails.name,
      variant: promotion.productDetails.term,
      index: index.toString(),
      itemCategory: promotion.productDetails.productCategoryPaths.primary?.at(-1)?.name,
      itemCategory2: promotion.productDetails.productCategoryPaths.path1?.at(-1)?.name,
      itemCategory3: promotion.productDetails.productCategoryPaths.path2?.at(-1)?.name,
      itemCategory4: promotion.productDetails.productCategoryPaths.path3?.at(-1)?.name,
      productSku: promotion?.productDetails?.sku,
    };
  };

  return (
    <>
      {firstPromotion ? (
        <div className="limitedTimeOffer mb-73px md:mb-57px 2xl:mb-70px relative">
          <Text
            tag="h2"
            field={props.fields.title}
            className="font-bold text-xl 2xl:text-2xl leading-7.3 mb-4 2xl:pt-3 text-center"
          />
          {status != 'succeeded' && <Spinner />}
          {firstPromotion != null && (
            <div className="offerContainer border rounded border-base-normal p-6 bg-white flex md:space-x-6 flex-col md:flex-row">
              <div className="w-full md:w-1/3 relative">
                <Image
                  src="https://cdn-www.avid.com/-/media/Webstore/SpotlightProducts/MC_Ultimate.png"
                  alt=""
                  width={640}
                  height={406}
                />
              </div>

              <div className="flex flex-col text-almost-black w-full">
                <h3 className="text-center md:text-left font-bold text-base 2xl:text-lg mb-2">
                  {firstPromotion.displayName}
                </h3>
                <p className="text-center md:text-left text-base mb-4">
                  {firstPromotion.productDetails.description}{' '}
                  <a className="underline text-left text-base text-primary hover:cursor-pointer">
                    {t('Cart_Details')}
                  </a>
                </p>
                <div className="flex justify-between">
                  <div className="flex flex-col space-y-0.5">
                    <h3 className="line-through text-base-dark text-base">
                      {t(`Currency_Symbol_${currencyIsoCode}`)}
                      {firstPromotion.productDetails.price}
                    </h3>
                    <h3 className="font-bold text-xl 2xl:text-2xl text-secondary">
                      {t(`Currency_Symbol_${currencyIsoCode}`)}
                      {Number.parseFloat(firstPromotion.discountPrice).toFixed(2)}
                    </h3>
                  </div>
                  <div className="mt-auto relative">
                    {cartAddItemStatus == 'loading' && <Spinner height={15} width={3} margin={3} />}
                    {productInCart ? (
                      <button
                        onClick={(): void => onClickInCartHandler()}
                        className="text-center font-bold text-base btn-feature py-10px px-3"
                      >
                        {t('Cart_In_Cart')}
                      </button>
                    ) : (
                      <button
                        onClick={(): void => {
                          onClickAddToCartHandler(firstPromotion.productDetails.productId);
                        }}
                        id={`buyNowBtn_${firstPromotion?.productDetails?.productId ?? ''}`}
                        data-id={firstPromotion?.productDetails?.productId ?? ''}
                        className="text-center font-bold text-base btn-feature py-10px px-3"
                      >
                        {t('Cart_Add')}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default withDatasourceCheck()<LimitedTimeOfferProps>(LimitedTimeOffer);
