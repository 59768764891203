import {
  AtomicResultSectionExcerpt,
  AtomicResultSectionTitle,
  Result,
  AtomicResultHtml,
  AtomicResultLink,
  AtomicResultSectionBottomMetadata,
  AtomicResultSectionBadges,
} from '@coveo/atomic-react';
const styles = `
  atomic-result-section-title,
  atomic-result-section-excerpt {
    margin-top: 0!important;
    margin-bottom: .5rem!important;
  }

  atomic-result-section-title atomic-result-text,
  .intro-text {
    color: #194BF5;
    font-family: var(--font-gtWalshiemAvidRegular)
    font-size: 1.25rem;
    line-height: 1.875rem;
  }

  @media (min-width: 992px) {
    atomic-result-section-title atomic-result-text,
    .intro-text {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }

  atomic-result-section-excerpt {
    max-height: 3rem!important;
  }

  atomic-html,
  .body {
    color: #222222;
    font-family: var(--font-gtWalshiemAvidRegular)
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .body-link {        
    color: #194BF5;
    font-family: GT Walsheim Avid;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
  }

  atomic-result-section-bottom-metadata {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-top: .5rem!important;
    max-height: none!important;
  }
    
  atomic-result-section-badges,
  atomic-result-section-title,
  atomic-result-section-excerpt {
    margin-top: 0!important;
    margin-bottom: .5rem!important;
  }
  
  atomic-result-section-badges {
    overflow: visible !important;
    color: #222222;
    font-size: .75rem;
    letter-spacing: .2em;
    line-height: 1.125rem;
    text-transform: uppercase;
    grid-column: span 3 !important;
  }
  
`;
export const CourseTemplate: React.FC<{ result: Result; label: string }> = ({ result, label }) => {
  const { coursecode } = result.raw;
  let displayCourseCodes = '';
  if (Array.isArray(coursecode)) {
    const coursecodeArray: unknown[] = coursecode;
    displayCourseCodes = coursecodeArray.map((item) => item).join(', ');
  }

  return (
    <>
      <style>{styles}</style>
      {displayCourseCodes && (
        <AtomicResultSectionBadges>{displayCourseCodes as string}</AtomicResultSectionBadges>
      )}
      <AtomicResultSectionTitle>
        <AtomicResultLink />
      </AtomicResultSectionTitle>
      <AtomicResultSectionExcerpt>
        <AtomicResultHtml field="searchdescription" />
      </AtomicResultSectionExcerpt>
      <AtomicResultSectionBottomMetadata>
        <>
          <a href={result.clickUri} className="body-link">
            {label}
          </a>
        </>
      </AtomicResultSectionBottomMetadata>
    </>
  );
};

export default CourseTemplate;
