import { Result } from '@coveo/atomic-react';
import { absoluteMediaUrl } from 'lib/utils/sitecore-media-utils';
import { usePrice } from 'lib/commerce/product/price-hook';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import ResultImage from 'components/atomic-components/ResultImage';
import { useState } from 'react';

const styles = `
    .result-root.with-sections.display-list.image-small {
      grid-template-areas:
      "visual"
      "title"
      "excerpt"
      "bottom-metadata"!important;
      grid-template-columns: auto!important;
    }
      .result-root.with-sections.display-grid button.btn-primary{
       background:#194BF5;
       color:white;
           font-size: 1.125rem;
    line-height: 1.6875rem;
    padding: 8.5px 1rem;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
       } 
    
       .result-root.with-sections.display-grid button.btn-primary:hover{
      background: #0F2D93;
      } 
      .result-root.with-sections.display-grid button.btn-primary:active{
      background: #050F31;
      } 
      .result-root.with-sections.display-grid button.btn-primary:disabled{
      background:#D1D5D7;
      }
      .result-root.with-sections
      {
      display:block;
      }
  
    @media (min-width: 768px) {
      .result-root.with-sections.display-list.image-small {
        grid-template-areas:
        "title title title visual"
        "excerpt excerpt excerpt visual"
        "bottom-metadata bottom-metadata bottom-metadata visual"!important;
        grid-template-columns: minmax(0px, min-content) auto 1fr minmax(0px, min-content)!important;
        grid-template-rows: auto auto auto!important;
      }
    }
  
    atomic-result-section-title,
    atomic-result-section-excerpt {
      margin-top: 0!important;
      margin-bottom: .5rem!important;
    }
  
    atomic-result-text {
      color: #194BF5;
      font-family: var(--font-gtWalshiemAvidRegular)
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
  
    @media (min-width: 992px) {
      atomic-result-text {
        font-size: 1.5rem;
        line-height: 2.25rem;
      }
    }
  
    atomic-html,
    .body {
      color: #222222;
      font-family: var(--font-gtWalshiemAvidRegular)
      font-size: 1rem;
      line-height: 1.5rem;
    }
  
    atomic-result-section-excerpt {
      max-height: 3rem!important;
    }
  
    .price-text {
      color: var(--Almost-Black, #222);
      font-size: 16px;
      line-height: calc(24 / 16 * 1em);
    }
  
    .price-text-strikethrough {
      font-size: 14px;
      text-decoration: line-through;
    }
  
    .bottom-metadata {
      display: flex;
      flex-direction: column;
      gap: 24px;
      grid-area: bottom-metadata / bottom-metadata / bottom-metadata / bottom-metadata!important;
      margin: 0!important;
      max-height: none!important;
      overflow: unset!important;
    }
  
    .result-links {
      display: flex;
      flex-direction: column;
      gap: .5rem;
      margin-top: .24px;
      max-height: none!important;
    }
  
    @media (min-width: 1200px) {
      .result-links {
        flex-direction: row;
        gap: 32.72px;
      }
    }
  
    .result-links > a {
      align-items: center;
      color: var(--atomic-pill-text);
      display: flex;
      font-family: var(--font-gtWalshiemAvidRegular)
      font-size: 18px;
      font-weight: 500;
      gap: 4px;
      line-height: calc(27 / 18 * 1em);
    }
  
    .result-links > a > .icon {
      display: inline-block;
    }
  
    .result-links > a {
      align-items: center;
      color: var(--atomic-pill-text);
      display: flex;
      font-family: var(--font-gtWalshiemAvidRegular)
      font-size: 18px;
      font-weight: 500;
      gap: 4px;
      line-height: calc(27 / 18 * 1em);
    }
  
    .result-links > a > .icon {
      display: inline-block;
    }
  
    .result-description {
      color: #222222;
      font-family: var(--font-gtWalshiemAvidRegular)
      font-size: 16px;
      line-height: calc(24 / 16 * 1em);
    }
  
    .result-media {
      aspect-ratio: 4/3;
      grid-area: visual;
      height: auto!important;
      margin: 0 0 24px;
      max-width: 100%;
    }
  
    @media (max-width: 767px) {
      .result-media {
        width: 100%!important;
      }
    }
  
    @media (min-width: 768px) {
      .result-media {
        margin: 0 0 0 1rem;
        max-width: 278px;
      }
    }
  
    .result-media > span {
      height: 100%!important;
      width: 100%!important;
    }
  
    .result-thumbnail {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
    .intro-text {
      color: #194BF5;
      font-family: var(--font-gtWalshiemAvidRegular);
      font-size: 1.5rem;
      line-height: 2.25rem;
      font-weight: 400;      
    }
    .price-text-wrapper {
       font-family: var(--font-gtWalshiemAvidRegular)
      font-size: 1.5rem;
     }
    @media (max-width: 991px) {
      .intro-text {
        font-size: 1.25rem;
      }
    }
    @media (max-width: 991px) {
      .price-text-wrapper {
        font-size: 1rem;
      }
    }
    .add-to-card-button {
      padding-block: 10px 10px;
      font-family: var(--font-gtWalshiemAvidRegular)
    }

    .pluginTitle{
    margin-bottom:96px;}
    .priceCartButtons{
    position: absolute;
    bottom: 0px;
    text-align: center;
    width: 90%;
    }
      .pluginImageWrapper{
      aspect-ratio:4/3;
      position:relative;
      overflow:hidden;
      width:100%;
    
      }
      .pluginImageWrapper img{
      position:relative !important;
      height:auto !important;
      }
  `;

export const PluginLandingPageTemplate: React.FC<{
  result: Result;
  currencyIsoCode: string;
  currencySymbol: string;
  addToCartHandler: (
    productId: string,
    productName: string,
    price: string,
    productcategory: string,
    productCode: string,
    brand: string
  ) => void;
  addToCartButtonLabel: string;
  removeFromCartButtonLabel: string;
}> = ({
  result,
  currencyIsoCode,
  currencySymbol,
  addToCartHandler,
  addToCartButtonLabel,
  removeFromCartButtonLabel,
}) => {
  const { searchtitle, productid, searchimageurl, isInCart, productcategory, productCode, brand } =
    result.raw;
  let isCDNURL = false;
  let absoluteSearchImageUrl =
    searchimageurl !== undefined ? `${absoluteMediaUrl(searchimageurl as string)}` : null;
  const price = usePrice(productid ? (productid as string) : '', currencyIsoCode);
  const [inCart, setInCart] = useState(isInCart);
  if (
    !absoluteSearchImageUrl ||
    absoluteSearchImageUrl == undefined ||
    absoluteSearchImageUrl == ''
  ) {
    absoluteSearchImageUrl =
      'https://images.avid.com/avidcom/images/products/plugin-default-image.jpg';
    isCDNURL = true;
  }

  return (
    <>
      <style>{styles}</style>
      <div className="rounded bg-white ">
        <div className=" pluginImageWrapper aspect-4/3 relative overflow-hidden">
          <span className="">
            {absoluteSearchImageUrl &&
              (isCDNURL ? (
                <ResultImage
                  src={absoluteSearchImageUrl}
                  className="min-w-full min-h-full col-span-12 w-[100%] object-center lg:col-span-8 xl:col-span-7 object-cover"
                  alt={searchtitle ? (searchtitle as string) : ''}
                />
              ) : (
                <NextImage
                  field={{
                    value: {
                      src: absoluteSearchImageUrl,
                      alt: searchtitle ? (searchtitle as string) : '',
                    },
                  }}
                  priority={false}
                  layout="fill"
                  className="w-full object-cover bg-base-lighter relative flex justify-center"
                />
              ))}
          </span>
        </div>
        <div className="text-center relative p-3 pt-2 pluginTitle  mb-24">
          <div className="intro-text text-primary pb-1">
            <div>{searchtitle as string}</div>
          </div>
        </div>
        <div className="priceCartButtons">
          {price && (
            <div className="accent-2 tracking-medium text-xl price-text-wrapper">
              {currencySymbol}
              {price?.listPrice}
            </div>
          )}
          {price && price?.listPrice > 0 && (
            <div className="flex justify-center items-center h-screen">
              {inCart ? (
                <>
                  <button
                    className="text-center !bg-primary font-bold text-base btn-primary px-3 my-4 add-to-card-button text-xl leading-24"
                    disabled
                  >
                    {removeFromCartButtonLabel}
                  </button>
                </>
              ) : (
                <>
                  <button
                    data-id={productid}
                    id={`buyNowBtn_${productid}`}
                    className="text-center !bg-primary font-bold text-base btn-primary px-3 my-4 add-to-card-button text-xl leading-24"
                    onClick={(e): void => {
                      e.stopPropagation();
                      addToCartHandler(
                        productid ? (productid as string) : '',
                        searchtitle ? (searchtitle as string) : '',
                        price?.listPrice ? (price?.listPrice as unknown as string) : '',
                        productcategory &&
                          Array.isArray(productcategory) &&
                          productcategory.length > 0
                          ? (productcategory[0] as string)
                          : '',
                        productCode ? (productCode as string) : '',
                        brand ? (brand as string) : ''
                      );
                      setInCart(true);
                    }}
                  >
                    {addToCartButtonLabel}
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PluginLandingPageTemplate;
