import { Text, withDatasourceCheck, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { PricingCardDataSource, PricingCardProps } from './pricing-card-props';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { addCartItem } from 'components/cart/cart-slice';
import Spinner from 'components/spinner/Spinner';
import { getProductCartIndex, pushAddToCart, pushViewItem } from 'lib/google-analytics/commerce';
import BasePricingCard from '../../components/pricing-card/BasePricingCard';
import { usePrice } from 'lib/commerce/product/price-hook';
import { useEffect, useMemo } from 'react';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';
import { useRouter } from 'next/router';

const PricingCard = (props: PricingCardProps<PricingCardDataSource>): JSX.Element => {
  const dispatch = useAppDispatch();

  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode);
  const productItem = props.fields?.data?.contextItem?.product?.targetItems[0] ?? null;
  const productId = productItem?.productId.value ?? '';
  const price = usePrice(productId, currencyIsoCode);
  const router = useRouter();

  const cart = useAppSelector((state) => state.cart);
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);

  const context = useSitecoreContext();
  const pageTemplateId = context?.sitecoreContext.route?.templateId;
  const customerInfo = useAppSelector((state) => state?.customer?.customerData);
  useEffect(() => {
    if (price == null || productItem == null || currencyIsoCode == null || props.stopEvents) {
      return;
    }

    pushViewItem(
      currencyIsoCode,
      price.unitPrice.toString(),
      {
        id: productItem.productId?.value,
        sku: productItem.productId?.value,
        name: productItem.productName.value,
        price: price.listPrice.toString(),
        discount: (price.listPrice - price.unitPrice).toString(),
        brand: productItem.brand.value,
        variant: productItem?.term?.value,
        itemCategory: productItem.primaryCategory?.targetItems[0]?.categoryName?.value,
        itemCategory2: productItem.additionalCategories?.targetItems[0]?.categoryName?.value,
        itemCategory3: productItem.additionalCategories?.targetItems[1]?.categoryName?.value,
        itemCategory4: productItem.additionalCategories?.targetItems[2]?.categoryName?.value,
        productSku: productItem.productCode?.value,
      },
      (customerInfo?.contactId as string) || ''
    );
  }, [
    currencyIsoCode,
    customerInfo?.contactId,
    dispatch,
    pageTemplateId,
    price,
    productItem,
    props.stopEvents,
  ]);

  const addToCartHandler = (): void => {
    dispatch(addCartItem({ sku: productId, quantity: 1, bearerToken: bearerToken }))
      .unwrap()
      .then(() => {
        if (
          price == null ||
          currencyIsoCode == null ||
          productItem == null ||
          cart.cartSummaryData == null
        ) {
          return;
        }

        const index = getProductCartIndex(productId, cart.cartItemsData);
        pushAddToCart(currencyIsoCode, price.unitPrice.toString(), {
          id: productId,
          sku: productId,
          name: productItem.productName.value,
          brand: productItem.brand.value,
          price: price.listPrice.toString(),
          discount: (price.listPrice - price.unitPrice).toString(),
          variant: productItem?.term?.value,
          index: index.toString(),
          itemCategory: productItem.primaryCategory?.targetItems[0]?.categoryName?.value,
          itemCategory2: productItem.additionalCategories?.targetItems[0]?.categoryName?.value,
          itemCategory3: productItem.additionalCategories?.targetItems[1]?.categoryName?.value,
          itemCategory4: productItem.additionalCategories?.targetItems[2]?.categoryName?.value,
          productSku: productItem.productCode.value,
        });
        router.push('/cart');
      });
  };

  return (
    <BasePricingCard
      pricingCard={props}
      fullCta={
        <button
          className="btn-primary ml-auto w-full px-8 relative"
          id={`buyNowBtn_${productId}`}
          data-id={productId}
          onClick={addToCartHandler}
        >
          <Text field={props.fields?.data?.datasource?.buttonLabel?.jsonValue} />
          {cart.cartAddItemStatus == 'loading' && <Spinner height={15} width={3} margin={3} />}
        </button>
      }
      miniCta={
        <button className="btn-primary ml-auto px-3 py-1 text-[1rem]" onClick={addToCartHandler}>
          <Text field={props.fields?.data?.datasource?.buttonLabel?.jsonValue} />
        </button>
      }
      productPricing={price}
      productItem={productItem}
      loading={price == null}
    />
  );
};

export default withDatasourceCheck()(PricingCard);
