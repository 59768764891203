import {
  AtomicResultList,
  AtomicSearchInterface,
  AtomicSearchBox,
  AtomicPager,
  AtomicSortDropdown,
  AtomicSortExpression,
  AtomicLayoutSection,
  AtomicDidYouMean,
  AtomicSearchBoxQuerySuggestions,
  AtomicSearchLayout,
  AtomicQuerySummary,
  AtomicResultDate,
  Result,
  AtomicResultsPerPage,
} from '@coveo/atomic-react';
import { useEffect, useState, useContext } from 'react';
import NoResults from 'components/atomic-components/NoResults';
import QueryError from 'components/atomic-components/QueryError';
import { useAppSelector } from 'lib/store/hooks';
import { absoluteMediaUrl } from 'lib/utils/sitecore-media-utils';
import { baseSearchResultStyles } from '../../components/search/base-search-results';
import CoveoScripts from 'components/search/CoveoScripts';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import ResultImage from 'components/atomic-components/ResultImage';
import { setCoveoQuery } from 'components/search/coveo-query-slice';
import { useAppDispatch } from 'lib/store/hooks';
import CoveoSearchEngineContext from 'lib/coveo/coveo-context';
import { ReferenceStringItem } from 'components/foundation/common/reference-string/reference-string-props';
import { ComponentProps } from 'lib/component-props';
import { SearchEngine } from '@coveo/headless';
import { toKeyValuePair } from 'lib/utils/string-format';

type PressRoomArchiveProps = ComponentProps & {
  fields: {
    coveoQuery: ReferenceStringItem;
    labels: { value: string };
  };
};

const PressRoomArchive = (props: PressRoomArchiveProps): JSX.Element => {
  const [hydrate, setHydrate] = useState(false);
  const navigationState = useAppSelector((state) => state.navigation);
  const searchEngine = useContext(CoveoSearchEngineContext) as unknown as SearchEngine;
  const dispatch = useAppDispatch();
  const coveoQuery = props.fields?.coveoQuery?.fields?.value?.value;
  const searchHubName = 'AvidComListing';
  useEffect(() => {
    setHydrate(true);
    dispatch(setCoveoQuery({ query: coveoQuery, searchHub: searchHubName }));

    return () => {
      dispatch(setCoveoQuery({ query: null, searchHub: null }));
    };
  }, [coveoQuery, dispatch]);

  const labels = props?.fields?.labels?.value ? toKeyValuePair(props?.fields?.labels?.value) : {};
  const t = (key: string): string => {
    return labels[key] || key;
  };

  return (
    <>
      <CoveoScripts />
      <div className="listing-interface"></div>
      {hydrate && <style>{baseSearchResultStyles}</style>}
      {hydrate && searchEngine && (
        <div className="max-w-content mx-auto lg:p-10">
          <AtomicSearchInterface
            engine={searchEngine}
            fieldsToInclude='["z95xcreated", "z95xz95xsmallcreateddate","summary","z95xtemplate","searchdescription","searchtitle","searchimageurl","documenttype","videourl","language","featuredimageurl"]'
            analytics={true}
            reflectStateInUrl={false}
            language={navigationState.currentLanguage}
            languageAssetsPath="/searchlabels"
            localization={(i18n): void => {
              i18n.addResourceBundle('en', 'translation', {
                search: t('PRESS_ROOM_SEARCH_PH_TEXT'),
              });
            }}
          >
            <AtomicSearchLayout class="search-layout lg:grid-cols-1">
              <AtomicLayoutSection section="main" class="search-main">
                <AtomicSearchBox
                  class="search-box mb-6"
                  suggestionDelay={900}
                  placeholder="Search press releases and media alerts"
                >
                  <AtomicSearchBoxQuerySuggestions />
                </AtomicSearchBox>

                <AtomicLayoutSection section="status" class="results-details">
                  <section className="results-count">
                    <AtomicQuerySummary />
                  </section>

                  <section className="results-manipulation">
                    <AtomicResultsPerPage choicesDisplayed="15,30,60,90" class="results-per-page" />

                    <AtomicSortDropdown class="results-sort-dropdown">
                      <AtomicSortExpression
                        label="Date (newer first)"
                        expression="z95xz95xsmallcreateddate descending"
                      />
                      <AtomicSortExpression
                        label="Date (older first)"
                        expression="z95xz95xsmallcreateddate ascending"
                      />
                      <AtomicSortExpression label="relevance" expression="relevancy" />
                    </AtomicSortDropdown>
                  </section>

                  <AtomicDidYouMean />
                </AtomicLayoutSection>
                <AtomicLayoutSection
                  section="results"
                  class="search-results block mb-[50px] change-layout"
                >
                  <AtomicResultList
                    template={(result: Result): JSX.Element => MyTemplate(result)}
                    imageSize="small"
                    display="grid"
                  />
                  <QueryError />
                  <NoResults enableCancelLastAction={false}></NoResults>
                </AtomicLayoutSection>
                <AtomicLayoutSection
                  section="pagination"
                  className="flex !flex-col justify-center items-center"
                >
                  <AtomicPager className="search-pagination pb-20" />
                </AtomicLayoutSection>
              </AtomicLayoutSection>
            </AtomicSearchLayout>
          </AtomicSearchInterface>
        </div>
      )}
      <br />
      <br />
    </>
  );
};

const styles = `
  .intro-text {
      color: #194BF5;
      font-family: var(--font-gtWalshiemAvidRegular);
      font-size: 1.5rem;
      line-height: 2.25rem;
      font-weight: 400;      
    }
    .result-root.with-sections.display-list.image-small {
      grid-template-areas:
      "visual"
      "title"
      "excerpt"
      "bottom-metadata"!important;
      grid-template-columns: auto!important;
    }
  
    @media (min-width: 768px) {
      .result-root.with-sections.display-list.image-small {
        grid-template-areas:
        "title title title visual"
        "excerpt excerpt excerpt visual"
        "bottom-metadata bottom-metadata bottom-metadata visual"!important;
        grid-template-columns: minmax(0px, min-content) auto 1fr minmax(0px, min-content)!important;
        grid-template-rows: auto auto auto!important;
      }
    }
  
    atomic-result-section-title,
    atomic-result-section-excerpt {
      margin-top: 0!important;
      margin-bottom: .5rem!important;
    }
  
    atomic-result-text {
      color: #194BF5;
      font-family: var(--font-gtWalshiemAvidRegular)
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
  
    @media (min-width: 992px) {
      atomic-result-text {
        font-size: 1.5rem;
        line-height: 2.25rem;
      }
    }
  
    atomic-html,
    .body {
      color: #222222;
      font-family: var(--font-gtWalshiemAvidRegular)
      font-size: 1rem;
      line-height: 1.5rem;
    }
  
    atomic-result-section-excerpt {
      max-height: 3rem!important;
    }
  
    .price-text {
      color: var(--Almost-Black, #222);
      font-size: 16px;
      line-height: calc(24 / 16 * 1em);
    }
  
    .price-text-strikethrough {
      font-size: 14px;
      text-decoration: line-through;
    }
  
    .bottom-metadata {
      display: flex;
      flex-direction: column;
      gap: 24px;
      grid-area: bottom-metadata / bottom-metadata / bottom-metadata / bottom-metadata!important;
      margin: 0!important;
      max-height: none!important;
      overflow: unset!important;
    }
  
    .result-links {
      display: flex;
      flex-direction: column;
      gap: .5rem;
      margin-top: .24px;
      max-height: none!important;
    }
  
    @media (min-width: 1200px) {
      .result-links {
        flex-direction: row;
        gap: 32.72px;
      }
    }
  
    .result-links > a {
      align-items: center;
      color: var(--atomic-pill-text);
      display: flex;
      font-family: var(--font-gtWalshiemAvidRegular)
      font-size: 18px;
      font-weight: 500;
      gap: 4px;
      line-height: calc(27 / 18 * 1em);
    }
  
    .result-links > a > .icon {
      display: inline-block;
    }
  
    .result-links > a {
      align-items: center;
      color: var(--atomic-pill-text);
      display: flex;
      font-family: var(--font-gtWalshiemAvidRegular)
      font-size: 18px;
      font-weight: 500;
      gap: 4px;
      line-height: calc(27 / 18 * 1em);
    }
  
    .result-links > a > .icon {
      display: inline-block;
    }
  
    .result-description {
      color: #222222;
      font-family: var(--font-gtWalshiemAvidRegular)
      font-size: 16px;
      line-height: calc(24 / 16 * 1em);
    }
  
    .result-media {
      aspect-ratio: 4/3;
      grid-area: visual;
      height: auto!important;
      margin: 0 0 24px;
      max-width: 100%;
    }
  
    @media (max-width: 767px) {
      .result-media {
        width: 100%!important;
      }
    }
  
    @media (min-width: 768px) {
      .result-media {
        margin: 0 0 0 1rem;
        max-width: 278px;
      }
    }
  
    .result-media > span {
      height: 100%!important;
      width: 100%!important;
    }
  
    .result-thumbnail {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
    @media (max-width: 991px) {
      .intro-text {
        font-size: 1.25rem;
      }
    }  
      .pressRoomArchive{
      aspect-ratio:2/1;
      position:relative;
      overflow:hidden;
      width:100%;
    
      } 
       .searchDescription{
     overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    
      }
      .pressRoomArchive img{
      position:relative !important;
      height:auto !important;
      }       
`;

const MyTemplate = (result: Result): JSX.Element => {
  const { searchtitle, searchimageurl, searchdescription, featuredimageurl } = result.raw;
  let isCDNURL = false;
  let absoluteSearchImageUrl =
    searchimageurl !== undefined ? `${absoluteMediaUrl(searchimageurl as string)}` : null;
  if (featuredimageurl) absoluteSearchImageUrl = featuredimageurl as string;
  if (
    !absoluteSearchImageUrl ||
    absoluteSearchImageUrl == undefined ||
    absoluteSearchImageUrl == ''
  ) {
    absoluteSearchImageUrl =
      'https://images.avid.com/avidcom/images/press-release/avid_logo_press.png';
    isCDNURL = true;
  }

  return (
    <>
      <style>{styles}</style>
      <div className="rounded bg-white overflow-hidden">
        <div className="text-left text-gray-600 mb-2">
          <AtomicResultDate field="z95xz95xsmallcreateddate" format="MMMM D, YYYY" />
        </div>
        <div className="pressRoomArchive overflow-hidden mb-2">
          {absoluteSearchImageUrl &&
            (isCDNURL || (featuredimageurl as string) != null ? (
              <ResultImage
                src={absoluteSearchImageUrl}
                className="min-w-full min-h-full col-span-12 w-[100%] object-center lg:col-span-8 xl:col-span-7 object-cover"
                alt={searchtitle ? (searchtitle as string) : ''}
              />
            ) : (
              <NextImage
                field={{
                  value: {
                    src: absoluteSearchImageUrl,
                    alt: searchtitle ? (searchtitle as string) : '',
                  },
                }}
                priority={false}
                layout="fill"
                className="w-full object-cover bg-base-lighter relative flex justify-center"
              />
            ))}
        </div>
        <div className="intro-text text-primary pb-1 line-clamp-2">
          <div>{searchtitle as string}</div>
        </div>
        <div className="pb-1 searchDescription line-clamp-3">
          <div className="text-left ">{searchdescription as string}</div>
        </div>
      </div>
    </>
  );
};

export default PressRoomArchive;
