import {
  GetStaticComponentProps,
  Placeholder,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import {
  NavigationFolder,
  NavigationGroup,
  NavigationItem,
  NavigationLink,
  NavigationLinkWithSocialIcon,
} from '../../components/global-navigation/shop/navigation-props';

import dynamic from 'next/dynamic';
import { GraphQLClient } from 'lib/common/graphql-client';
import { useEffect, useMemo, useState } from 'react';
import { getStoredCountry } from 'lib/commerce/countries/countries-provider';
import { geoRestrictDataNavigationItems } from 'lib/navigation/navigation-utils';
import getFooterQuery from '../../components/footer/footer-query';
import { useSSRErrorsHandler } from 'src/opentelemetry/handle-ssr-errors-hook';
import { SsrErrorPropsCreator } from 'src/opentelemetry/elastic-rum-ssrlog-creator';

const NavigationElement = dynamic(
  () => import('../../components/global-navigation/shop/NavigationElement'),
  {
    ssr: false,
  }
);

type FooterProps = ComponentProps & {
  fields: {
    data: NavigationFolder;
  };
  navigationFolder: NavigationFolder | null;
};

const Footer = (props: FooterProps): JSX.Element => {
  useSSRErrorsHandler(props.ssrErrors);
  const data: NavigationFolder | null = props?.navigationFolder;
  const navigationGroups = data?.datasource?.children?.results as NavigationGroup[];
  const line1 = useMemo(() => {
    return navigationGroups ? (navigationGroups[0].children.results as NavigationLink[]) : [];
  }, [navigationGroups]);

  const line2 = useMemo(() => {
    return navigationGroups ? (navigationGroups[1].children.results as NavigationLink[]) : [];
  }, [navigationGroups]);

  const socialLinks = useMemo(() => {
    return navigationGroups
      ? (navigationGroups[2].children.results as NavigationLinkWithSocialIcon[])
      : [];
  }, [navigationGroups]);

  const country = getStoredCountry();

  const [cleanLine1, setCleanLine1] = useState<NavigationItem[]>(JSON.parse(JSON.stringify(line1)));

  const [cleanLine2, setCleanLine2] = useState<NavigationItem[]>(JSON.parse(JSON.stringify(line2)));

  const [cleanSocialLinks, setCleanSocialLinks] = useState<NavigationItem[]>(
    JSON.parse(JSON.stringify(socialLinks))
  );

  useEffect(() => {
    if (!country) {
      return;
    }
    if (line1 != null) {
      setCleanLine1(geoRestrictDataNavigationItems(country, line1));
    }

    if (line2 != null) {
      setCleanLine2(geoRestrictDataNavigationItems(country, line2));
    }

    if (socialLinks != null) {
      setCleanSocialLinks(geoRestrictDataNavigationItems(country, socialLinks));
    }
  }, [line1, line2, socialLinks, country]);
  const footerAlignmentClass = 'ml-0 md:ml-0 ';
  return (
    <div className="container text-sm mx-auto">
      {cleanLine1 && cleanLine1.length > 0 && (
        <ul className="flex flex-col gap-y-6 justify-center items-center md:space-x-4 flex-wrap  mb-12 md:flex-row md:gap-y-1 md:mb-6">
          {cleanLine1.map((navigationLink: NavigationLink, index) => (
            <li
              key={index}
              className={`${
                index == 0 ? footerAlignmentClass : ''
              }flex items-center text-white  font-avidwalsheimbold`}
            >
              <NavigationElement
                navigationItem={navigationLink}
                className="!text-xl !leading-7.5 focus:outline-none font-avidwalsheimbold hover:text-base-light active:text-base-normal focus-visible:ring-[2px] ring-accent-cool ring-offset-[3px] rounded ring-offset-almost-black"
              />
              {index !== cleanLine1.length - 1 && <div className="ml-4 hidden md:block">|</div>}
            </li>
          ))}
        </ul>
      )}

      {cleanLine2 && cleanLine2.length > 0 && (
        <ul className="flex flex-col gap-y-6 justify-center items-center md:space-x-4 flex-wrap mb-12 md:flex-row md:gap-y-1 md:mb-6">
          {cleanLine2.map((navigationLink, index) => (
            <li key={index} className="flex items-center text-white !text-xl !leading-7.5">
              {index === 0 && <>© {new Date().getFullYear()} &nbsp;</>}
              <NavigationElement
                navigationItem={navigationLink}
                className="!text-xl !leading-7.5 focus:outline-none font-avidwalsheim hover:text-base-light active:text-base-normal focus-visible:ring-[2px] ring-accent-cool ring-offset-[3px] rounded ring-offset-almost-black"
              />
              {index !== cleanLine2.length - 1 && <div className="ml-4 hidden md:block">|</div>}
            </li>
          ))}
        </ul>
      )}

      {cleanSocialLinks && cleanSocialLinks.length > 0 && (
        <ul className="flex justify-center items-center gap-x-8 flex-wrap gap-y-8 text-base-medium-dark">
          {cleanSocialLinks.map((navigationLink, index) => (
            <li key={index} className="flex">
              <NavigationElement
                navigationItem={navigationLink}
                className="focus:outline-none hover:text-base-medium active:text-base-normal focus-visible:ring-[2px] ring-accent-cool ring-offset-[3px] rounded ring-offset-almost-black"
              />
            </li>
          ))}
        </ul>
      )}
      <div className="flex justify-center items-center mt-8">
        <Placeholder name="jss-footer-additions" rendering={props.rendering} />
      </div>
    </div>
  );
};

/**
 * Will be called during SSG
 * @param {ComponentRendering} rendering
 * @param {LayoutServiceData} layoutData
 */
export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  if (rendering?.dataSource == null || layoutData?.sitecore?.context?.language == null) {
    return {};
  }

  const ssrErrorLogger = new SsrErrorPropsCreator();

  const navigationFolder = await getFooterData(
    rendering.dataSource,
    layoutData.sitecore.context.language
  );
  if (!navigationFolder) {
    ssrErrorLogger.logError({
      functionName: 'getFooterData; ',
      errorMessage: `Failed to get response; Datasource: ${JSON.stringify(rendering.dataSource)};
      Langusge: ${layoutData.sitecore.context.language};
      Response: ${JSON.stringify(navigationFolder)}`,
    });
  }

  const propsObj = {
    navigationFolder,
    rendering,
  };
  return ssrErrorLogger.createProperty('Footer', propsObj);
};

export const getFooterData = async (
  datasource: string,
  language: string
): Promise<NavigationFolder> => {
  const graphQLClient = GraphQLClient();
  return await graphQLClient.request<NavigationFolder>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFooterQuery,
    {
      datasource: datasource,
      language: language,
    }
  );
};

export default withDatasourceCheck()<FooterProps>(Footer);
