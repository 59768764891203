import { engage } from 'lib/personalize/load-engage';
import { getCookie } from 'cookies-next';

export const sendPageViewEvent = async (): Promise<void> => {
  if (!engage) return;

  try {
    await engage.pageView({
      channel: 'Web',
      language: (getCookie('LOCALE') as string) || 'en',
      currency: (getCookie('currency') as string) || 'USD',
      page: window.location.pathname,
    });
  } catch (error) {
    console.error('Error sending page view event:', error);
  }
};
