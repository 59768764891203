import { ComponentProps } from 'lib/component-props';
import LocalNavigationMobile from '../components/local-navigation/LocalNavigationMobile';
import LocalNavigationDesktop from '../components/local-navigation/LocalNavigationDesktop';
import {
  GetStaticComponentProps,
  Placeholder,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { GraphQLClient } from 'lib/common/graphql-client';
import getLocalNavigationQuery from '../components/local-navigation/local-navigation-query';
import { LocalNavigationFolder } from 'components/local-navigation/local-navigation-props';

type LocalNavigationProps = ComponentProps & {
  fields: {
    data: LocalNavigationFolder;
    params: {
      MobileNavBarTextAlignment: string;
    };
  };
};

const LocalNavigation = (props: LocalNavigationProps): JSX.Element => {
  const menuItems = props.fields?.data?.datasource?.children?.results;
  const pageId = props.fields?.data?.contextItem?.id;
  return (
    <div className="lg:flex lg:justify-center lg:mx-auto lg:px-8 lg:space-x-8 lg:box-content lg:my-20 2xl:px-24 max-w-screen-xl">
      <div className="lg:grid lg:grid-cols-12">
        {menuItems && pageId && (
          <div className="lg:col-span-3 lg:mr-8">
            <LocalNavigationDesktop menuItems={menuItems} pageId={pageId} />
            <LocalNavigationMobile
              menuItems={menuItems}
              pageId={pageId}
              alignment={props.params.MobileNavBarTextAlignment}
            />
          </div>
        )}

        <div
          className={`${
            menuItems && pageId
              ? 'lg:col-span-9 px-6 py-10 lg:p-0 lg:px-0'
              : 'lg:col-span-12 py-10 lg:p-0 px-6 lg:px-0'
          }`}
        >
          <Placeholder name="local-nav-content" rendering={props.rendering} />
        </div>
      </div>
    </div>
  );
};

/**
 * Will be called during SSG
 * @param {ComponentRendering} rendering
 * @param {LayoutServiceData} layoutData
 */
export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  if (
    rendering?.dataSource == null ||
    rendering?.dataSource == '' ||
    layoutData?.sitecore?.context?.language == null
  ) {
    return {};
  }

  const contextItemId = layoutData?.sitecore?.route?.itemId as string;
  const language = layoutData.sitecore.context.language;
  const localNavigationResponse = await getLocalNavigationData(
    rendering.dataSource,
    contextItemId,
    language
  );
  return {
    fields: {
      data: localNavigationResponse,
    },
    params: rendering.params,
  };
};

export const getLocalNavigationData = async (
  datasource: string,
  contextItemId: string,
  language: string
): Promise<LocalNavigationFolder> => {
  const graphQLClient = GraphQLClient();

  return await graphQLClient.request(getLocalNavigationQuery, {
    datasource: datasource,
    contextItem: contextItemId,
    language: language,
  });
};

export default withDatasourceCheck()<LocalNavigationProps>(LocalNavigation);
