import { useRouter } from 'next/router';
import { ParsedUrlQueryInput } from 'querystring';
import dynamic from 'next/dynamic';
import React, { useEffect, useMemo, useState } from 'react';
import { useWebStoreCurrencies } from 'lib/commerce/webstore/webstore-hooks';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { changeCurrency } from 'components/cart/cart-slice';
import { getLanguages } from 'lib/navigation/navigation-utils';
import { navigationActions } from 'lib/navigation/navigation-slice';
import { useI18n } from 'next-localization';
import { LanguageCurrencySwitcherProps } from 'components/language-currency-switcher/language-currency-switcher-props';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';

const Modal = dynamic(() => import('../components/foundation/non-sitecore/Modal'), {
  ssr: false,
});

const DropdownMenu = dynamic(() => import('components/dropdown/shop/DropdownMenu'), {
  ssr: false,
});

const LanguageCurrencySwitcher = (props: LanguageCurrencySwitcherProps): JSX.Element => {
  const router = useRouter();
  const navigationState = useAppSelector((state) => state.navigation);
  const [showModal, setShowModal] = useState(navigationState.languageSwitcherVisible);
  const { pathname, asPath } = router;
  const { t } = useI18n();
  const query: string | ParsedUrlQueryInput = router.query;
  const [selectedLanguage, setSelectedLanguage] = useState(navigationState.currentLanguage);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const dispatch = useAppDispatch();
  const currencyIsoCode = useAppSelector((state) => state.cart?.currencyIsoCode);
  const currencies = useWebStoreCurrencies();
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);
  const cartItemData = useAppSelector((state) => state.cart.cartItemsData);
  const hasMidtermUpgrade = cartItemData?.cartItems.some(
    (item) => item.cartItem.transactionType == 'Mid Term Upgrade'
  );
  const isSearchPage = router.asPath.includes('/search');
  const isCurrencyChangeDisallowed = isSearchPage || hasMidtermUpgrade;

  useEffect(() => {
    if (currencyIsoCode !== null) {
      setSelectedCurrency(currencyIsoCode);
    }
    setSelectedLanguage(navigationState.currentLanguage);
    setShowModal(navigationState.languageSwitcherVisible);
  }, [dispatch, currencyIsoCode, navigationState]);

  const languageArray = getLanguages(props.fields?.data?.languages?.children?.results);

  const onChangeLanguageHandler = (lang: string): void => {
    setSelectedLanguage(lang);
  };

  const onChangeCurrencyHandler = (currency: string): void => {
    setSelectedCurrency(currency);
  };

  const onCloseModal = (): void => {
    setShowModal(false);
    dispatch(navigationActions.toggleSwitcherModal());
  };

  const onSubmitFormHandler = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    setShowModal(false);
    dispatch(navigationActions.toggleSwitcherModal());
    dispatch(navigationActions.changeLanguage(selectedLanguage));
    if (currencyIsoCode !== selectedCurrency)
      dispatch(changeCurrency({ currencyIsoCode: selectedCurrency, bearerToken: bearerToken }));
    if (pathname && asPath && query) {
      const searchInterface = document?.querySelector('atomic-search-interface');
      if (searchInterface) searchInterface.language = selectedLanguage;
      router.push(
        {
          pathname: pathname,
          query: query,
        },
        asPath,
        { locale: selectedLanguage }
      );
    }
  };

  const getLangName = (code: string): string => {
    return languageArray.find((l) => l.code === code)?.name || '';
  };

  const langOptions =
    useMemo(() => {
      return languageArray.map((l) => ({ label: l.name, value: l.code }));
    }, [languageArray]) || [];

  const currencyOptions =
    useMemo(() => {
      return currencies.map((c) => ({ label: c + ' ' + t(`Currency_Symbol_${c}`), value: c }));
    }, [currencies, t]) || [];

  return (
    <>
      <Modal
        onClose={onCloseModal}
        show={showModal}
        title={t('n_LocationSettings')}
        titleClassName="text-almost-black"
        className="w-350 z-50"
        rootId="language-switcher-root"
        useModalBackdrop={true}
      >
        <div>
          <form onSubmit={onSubmitFormHandler}>
            <div className="flex flex-col pt-6" style={{ pointerEvents: 'none', opacity: '0.4' }}>
              <label className="input-label">{t('n_Language')}</label>
              {languageArray && (
                <DropdownMenu
                  key={'language-menu'}
                  defaultValue={{ value: selectedLanguage, label: getLangName(selectedLanguage) }}
                  options={langOptions}
                  passValueToParentFunction={onChangeLanguageHandler}
                />
              )}
            </div>
            <div
              className="flex flex-col pt-6"
              style={isCurrencyChangeDisallowed ? { pointerEvents: 'none', opacity: '0.4' } : {}}
            >
              <label className="input-label">{t('n_Currency')}</label>
              <DropdownMenu
                key={'currency-menu'}
                defaultValue={{ value: selectedCurrency, label: selectedCurrency }}
                options={currencyOptions}
                passValueToParentFunction={onChangeCurrencyHandler}
              />
            </div>
            <div className="flex pt-8">
              <button type="submit" className="btn-primary">
                {t('n_Submit')}
              </button>
              <button
                type="button"
                className="btn-outline ml-3"
                onClick={(): void => onCloseModal()}
              >
                {t('n_Cancel')}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default LanguageCurrencySwitcher;
