import { Field, LinkField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { useI18n } from 'next-localization';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { changeCurrency } from 'components/cart/cart-slice';
import { useSession } from 'next-auth/react';
import { useCartData } from 'components/cart/cart-hooks';
import Spinner from 'components/spinner/Spinner';
import { AnalyticsProductItem, pushBeginCheckout } from 'lib/google-analytics/commerce';
import dynamic from 'next/dynamic';
import { getBearerToken } from 'lib/authentication/account-provider';
import { CartRuleSitecore } from '../cart/CartSummary';
import { getCartItemsLimits } from 'components/checkout/checkout-helpers';
const CouponDetails = dynamic(() => import('components/cart/shop/CouponDetails'));
const CartItem = dynamic(() => import('components/cart/shop/CartItem'));

type SummaryProps = ComponentProps & {
  fields: {
    data?: {
      datasource?: {
        title: Field<string>;
        eulaUS: Field<string>;
        eulaNonUS: Field<string>;
        link: {
          jsonValue: LinkField;
        };
        orderProcessingLink: {
          jsonValue: LinkField;
        };
        cartRules: {
          targetItems: CartRuleSitecore[];
        };
      } & CheckoutSummaryLabels;
    };
  };
};

export type CheckoutSummaryLabels = {
  checkoutGreetingUser: Field<string> | undefined;
  checkoutGreetingBase: Field<string> | undefined;
  checkoutProceedUS: Field<string> | undefined;
  checkoutProceedNonUS: Field<string> | undefined;
  checkoutTaxes: Field<string> | undefined;
  checkoutOrderTotal: Field<string> | undefined;
  cartTotal: Field<string> | undefined;
  checkoutSubTotal: Field<string> | undefined;
};

const CheckoutSummary = (props: SummaryProps): JSX.Element => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);

  const { checkoutStage, billingAddress } = useAppSelector((state) => state.checkout);

  const countries = useAppSelector((state) => state.countries.countriesData);

  const customerInfo = useAppSelector((state) => state.customer);
  const customerBillingCountry = billingAddress?.country;
  const customerDataStatus = customerInfo?.customerDataStatus;
  const customerFirstName = customerInfo.customerData?.firstName ?? billingAddress?.firstName;

  const cartItemData = useCartData();
  const cart = useAppSelector((state) => state.cart);
  const currencyIsoCode = cart?.currencyIsoCode;

  const cartItems = cartItemData?.cartItems;
  const cartSummary = cartItemData?.cartSummary;
  const cartItemStatus = cart.cartItemsDataStatus;

  const checkout = useAppSelector((state) => state.checkout);
  const applyCouponStatus = cart?.applyCouponStatus;

  const fastSpringEmbeddedStoreStatus = checkout.fastSpringEmbeddedStoreStatus;
  const fastSpringTaxData = checkout.fastSpringTaxData;
  const showFinalTotals = checkoutStage == 'payment-method';
  const total = parseFloat(cartSummary?.totalProductAmountAfterAdjustments ?? '0').toFixed(2);
  const totalAfterTaxes = fastSpringTaxData?.totalValue.toFixed(2) ?? 0;
  const subTotal = cartSummary?.totalProductAmount;
  const taxes = showFinalTotals ? fastSpringTaxData?.taxValue?.toFixed(2) ?? '0.00' : '0.00';
  const [lastTotal, setLastTotal] = useState(0);

  const cartItemsLimits = getCartItemsLimits(
    props.fields?.data?.datasource?.cartRules?.targetItems ?? []
  );
  const labels: CheckoutSummaryLabels = {
    checkoutGreetingUser: props?.fields?.data?.datasource?.checkoutGreetingUser,
    checkoutGreetingBase: props?.fields?.data?.datasource?.checkoutGreetingBase,
    checkoutProceedUS: props?.fields?.data?.datasource?.checkoutProceedUS,
    checkoutProceedNonUS: props?.fields?.data?.datasource?.checkoutProceedNonUS,
    checkoutTaxes: props?.fields?.data?.datasource?.checkoutTaxes,
    checkoutOrderTotal: props?.fields?.data?.datasource?.checkoutOrderTotal,
    cartTotal: props?.fields?.data?.datasource?.cartTotal,
    checkoutSubTotal: props.fields.data?.datasource?.checkoutSubTotal,
  };

  useEffect(() => {
    const updateCurrency = async (): Promise<void> => {
      const countryCurrency = countries.find(
        (countryData) => customerBillingCountry === countryData.countryCode
      )?.currencyISoCode;
      if (countryCurrency && countryCurrency !== currencyIsoCode) {
        await dispatch(
          changeCurrency({ currencyIsoCode: countryCurrency, bearerToken: bearerToken })
        );
      }
    };

    if (customerBillingCountry && countries?.length > 0) {
      updateCurrency();
    }
  }, [
    customerBillingCountry,
    customerDataStatus,
    dispatch,
    currencyIsoCode,
    bearerToken,
    countries,
  ]);

  useEffect(() => {
    // google analitycs event
    if (currencyIsoCode == null || cartItemData == null) {
      return;
    }

    const cartTotal = parseFloat(cartItemData.cartSummary.totalProductAmountAfterAdjustments);
    if (lastTotal == cartTotal) {
      return;
    }

    const couponCode = cartItemData?.cartSummary?.coupons?.[0]?.couponCode;

    pushBeginCheckout(
      currencyIsoCode,
      cartTotal.toString(),
      couponCode ?? '',
      cartItemData.cartItems.map((item, index): AnalyticsProductItem => {
        const cartItem = item.cartItem;
        return {
          id: cartItem.productId,
          sku: cartItem.productDetails.sku,
          name: cartItem.name,
          price: cartItem.salesPrice,
          discount: (
            parseFloat(cartItem.listPrice) -
            parseFloat(cartItem.salesPrice) +
            Math.abs(parseFloat(cartItem.totalAdjustmentAmount))
          )
            .toFixed(2)
            .toString(),
          variant: cartItem.productDetails.fields.XC_SubscriptionType__c,
          brand: cartItem.productDetails.fields.VendorName__c,
          index: index.toString(),
          quantity: cartItem.quantity,
          listName: 'Checkout',
          listId: 'Checkout_0',
          itemCategory: cartItem.productCategoryPaths.primary?.at(-1)?.name,
          itemCategory2: cartItem.productCategoryPaths.path1?.at(-1)?.name,
          itemCategory3: cartItem.productCategoryPaths.path2?.at(-1)?.name,
          itemCategory4: cartItem.productCategoryPaths.path3?.at(-1)?.name,
          productSku: cartItem.productDetails.sku,
        };
      }),
      (customerInfo?.customerData?.contactId as string) || ''
    );
    setLastTotal(cartTotal);
  }, [cartItemData, currencyIsoCode, customerInfo?.customerData?.contactId, lastTotal]);

  return (
    <>
      <div className="summary relative w-full max-w-450 xl:max-w-none mx-auto border border-base-normal p-6 rounded bg-white overflow-hidden">
        <h3 className="text-almost-black font-bold mb-4 text-2xl 2xl:text-28 text-center xl:text-left">
          {customerFirstName != null && (
            <span>{`${labels?.checkoutGreetingUser?.value} ${customerFirstName}, `}</span>
          )}
          <span>{labels.checkoutGreetingBase?.value}</span>
        </h3>

        {(cartItemStatus == 'loading' ||
          fastSpringEmbeddedStoreStatus == 'loading' ||
          applyCouponStatus === 'loading') && <Spinner />}
        {cartItems != null && cartSummary != null && (
          <>
            {cartItems.map((item) => {
              return (
                <CartItem
                  key={item.cartItem.cartItemId}
                  cartItem={item.cartItem}
                  currencyIsoCode={cartSummary.currencyIsoCode}
                  editable={checkoutStage !== 'payment-method'}
                  showPricingAndTerms={showFinalTotals}
                  quantityLimits={cartItemsLimits.find(
                    (el) => el.productId === item.cartItem.productId
                  )}
                />
              );
            })}
            <div className="wrapper flex flex-col text-almost-black">
              <CouponDetails isEditable={!showFinalTotals} />

              {showFinalTotals && (
                <>
                  {cartSummary.coupons.length <= 0 && (
                    <h3 className="font-normal text-base flex justify-between space-x-10 mb-2">
                      <span>{labels?.checkoutSubTotal?.value}</span>
                      <span>
                        {t(`Currency_Symbol_${currencyIsoCode}`)}
                        {subTotal}
                      </span>
                    </h3>
                  )}
                  <h3 className="font-normal text-base flex justify-between space-x-10 mb-2">
                    <span>{labels.checkoutTaxes?.value}</span>
                    <span>
                      {t(`Currency_Symbol_${currencyIsoCode}`)}
                      {taxes}
                    </span>
                  </h3>
                </>
              )}

              <h3 className="font-normal text-2xl flex justify-between space-x-10 mb-4">
                <span>{`${labels.cartTotal?.value}`}</span>
                <span className="font-bold">
                  {t(`Currency_Symbol_${currencyIsoCode}`)}
                  {totalAfterTaxes != 0 ? totalAfterTaxes : total}
                </span>
              </h3>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default withDatasourceCheck()<SummaryProps>(CheckoutSummary);
