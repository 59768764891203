import { DropdownItem } from '../inline-heading-and-dropdown/dropdown';
import { MultiTierPricingCardPropFields } from '../../renderings/MultiTierPricingCard';
import { MultiTierPricingTableCardResponse } from './multi-tier-pricing-table-card-response';
import {
  DropdownTypeReferenceResponse,
  MultiTierPricingTableResponse,
} from './multi-tier-pricing-table-response';
import { Variant } from 'lib/commerce/product/model/product-models';

export const MapToProps = (
  tableResponse: MultiTierPricingTableResponse,
  cardResponses: Array<MultiTierPricingTableCardResponse>
): MultiTierPricingCardPropFields => {
  return {
    productTableId: tableResponse.datasource.productTableId.jsonValue,
    productTableName: tableResponse.datasource.name,
    defaultCardForMobile: tableResponse?.datasource?.defaultCardForMobile?.targetIds,
    cards: cardResponses
      .map((card) => {
        const cleanProducts = card.datasource.product?.targetItems.filter((productItem) => {
          return productItem?.productId?.jsonValue != null;
        });

        return {
          id: card.datasource.id,
          fields: {
            product: cleanProducts.map((productItem) => {
              return {
                displayName: '',
                fields: {
                  productId: productItem.productId.jsonValue,
                  productCode: productItem.productCode.jsonValue,
                  productImage: {},
                  productImageThumbnail: {},
                  productName: productItem.productName.jsonValue,
                  term: productItem.term.jsonValue,
                  shortDescription: productItem.shortDescription.jsonValue,
                  features: [],
                  brand: productItem.brand.jsonValue,
                  primaryCategory: [],
                  additionalCategories: [],
                },
                id: '',
                name: '',
                url: '',
              };
            }),
            features: card.datasource.features?.targetItems.map((featureItem) => {
              return {
                fields: {
                  heading: featureItem.heading.jsonValue,
                  modalLinkText: featureItem.modalLinkText.jsonValue,
                  modal:
                    featureItem.modal.targetItem != null
                      ? {
                          fields: {
                            heading: featureItem.modal.targetItem?.heading.jsonValue,
                            body: featureItem.modal.targetItem?.body.jsonValue,
                            link: featureItem.modal.targetItem?.link.jsonValue,
                          },
                        }
                      : null,
                },
              };
            }),
            primaryCta: {
              value: card.datasource.primaryCta.jsonValue?.value,
            },
            secondaryCta: {
              value: card.datasource.secondaryCta.jsonValue?.value,
            },
            description: card.datasource.description.jsonValue,
            featuredTheme: {
              fields: {
                value: card.datasource.featuredTheme.targetItem?.value?.jsonValue,
              },
            },
            featuredText: card.datasource.featuredText.jsonValue,
            featuresHeading: card.datasource.featuresHeading.jsonValue,
            badgeText: card.datasource.badgeText.jsonValue,
            enterpriseDescription: card.datasource.enterpriseDescription?.jsonValue ?? null,
          },
        };
      })
      .filter((card) => {
        return card?.fields?.product?.length && card?.fields?.product[0].fields?.productId != null;
      }),
    footerContent: tableResponse.datasource.footerContent.jsonValue,
    link: tableResponse.datasource.link.jsonValue,
    badCombinationMessage: tableResponse.datasource.badCombinationMessage?.jsonValue,
    defaultFrequencyType: mapDropdownTypeReference(tableResponse.datasource.defaultFrequencyType),
    frequencyTypeOptions: mapDropdownTypeReference(tableResponse.datasource.frequencyTypeOptions),
    defaultUserType: mapDropdownTypeReference(tableResponse.datasource.defaultUserType),
    userTypeOptions: mapDropdownTypeReference(tableResponse.datasource.userTypeOptions),
  };
};

const mapDropdownTypeReference = (
  dropdownTypeField: DropdownTypeReferenceResponse
): Array<DropdownItem> => {
  return dropdownTypeField.targetItems.map((dropdownItem) => {
    return {
      fields: {
        title: dropdownItem?.title?.jsonValue,
        value: dropdownItem?.value?.jsonValue,
      },
    };
  });
};

export const mapVariantsToMobileVariants = (
  productVariants: Variant[],
  productId: string
): Variant[] => {
  const newProductVariants = JSON.parse(JSON.stringify(productVariants));
  const defaultProductIndex = newProductVariants?.findIndex((x) => x.productId == productId);
  if (defaultProductIndex > 0) arraymove(newProductVariants, defaultProductIndex, 0);
  return newProductVariants;
};

const arraymove = (arr: Variant[], fromIndex: number, toIndex: number): void => {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
};
