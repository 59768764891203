import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

type AccordionProps = {
  tabs: {
    title: JSX.Element;
    content: JSX.Element;
  }[];
};

const Accordion = (props: AccordionProps): JSX.Element => {
  const [isOpenArray, setIsOpenArray] = useState(props.tabs.map(() => false));

  const onClickToggleTabHandler = (index: number): void => {
    const newArray = [...isOpenArray];
    if (isOpenArray[index]) newArray[index] = false;
    else newArray[index] = true;

    setIsOpenArray(newArray);
  };

  return (
    <ul>
      {props.tabs.map((tab, index) => (
        <li key={index}>
          <details className="accordionList border-b border-neutral-light open:bg-base-lighter open:border-b-primary open:hover:shadow-[0_0_16px_0_rgba(34,34,34,0.16)] disabled:text-base-normal">
            <summary
              onClick={(): void => onClickToggleTabHandler(index)}
              className="p-4 label flex justify-between items-center hover:cursor-pointer focus-visible:outline-primary text-left font-normal"
            >
              {tab.title}
              <FontAwesomeIcon
                icon={isOpenArray[index] ? faChevronUp : faChevronDown}
                className="text-xs text-primary"
              />
            </summary>
            {tab.content}
          </details>
        </li>
      ))}
    </ul>
  );
};

export default Accordion;
