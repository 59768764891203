import { NavigationGroup } from '../global-navigation/shop/navigation-props';
import LocalNavigationContent from './LocalNavigationContent';

const LocalNavigationDesktop = (props: {
  menuItems: NavigationGroup[];
  pageId: string;
}): JSX.Element => {
  const { menuItems, pageId } = props;

  return (
    <div className="mx-auto flex-grow w-full justify-center hidden lg:block">
      <LocalNavigationContent
        menuItems={menuItems}
        pageId={pageId}
        containerClassName=""
        LinkClassName=" px-0"
        IconClassName="p-4"
      />
    </div>
  );
};

export default LocalNavigationDesktop;
