import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import { BrightcoveVideoProps } from '../components/video/brightcove-video-props';
import { getYoutubeThumbnail } from 'lib/youtube-thumbnail-fetcher';
import VideoPlayerIcon from 'components/video/VideoPlayerIcon';
import { useState, useEffect } from 'react';
import BaseVideoModal from 'components/video/BaseVideoModal';
import { YoutubeVideoProps } from '../components/video/youtube-video-props';

type VideoBlockProps = ComponentProps & {
  fields: {
    videos: (BrightcoveVideoProps | YoutubeVideoProps)[];
  };
};

export type youtubeThumbnailProps = {
  value: {
    src: string;
  };
};

const VideoBlock = (props: VideoBlockProps): JSX.Element => {
  let videoId, playerId;

  const video = props.fields?.videos?.[0];
  let videoThumbnail = video?.fields?.stillImage?.value?.src != null && video?.fields?.stillImage;

  const isBrightcoveVideo = (video as BrightcoveVideoProps)?.fields?.videoReference != undefined;
  const isYoutubeVideo = (video as YoutubeVideoProps)?.fields?.sourceId?.value != undefined;
  if (isBrightcoveVideo) {
    const thumbnailURL = (video as BrightcoveVideoProps)?.fields?.videoReference[0]?.fields
      ?.ThumbnailURL?.value as string;
    videoId = (video as BrightcoveVideoProps)?.fields?.videoReference[0]?.fields?.ID
      ?.value as string;
    playerId = (video as BrightcoveVideoProps)?.fields?.player?.fields?.ID?.value as string;

    if (!videoThumbnail && thumbnailURL) {
      videoThumbnail = { value: { src: thumbnailURL } };
    }
  }

  const [youtubeVideoThumbnail, setyoutubeVideoThumbnail] = useState<youtubeThumbnailProps>();
  if (isYoutubeVideo) {
    videoId = (video as YoutubeVideoProps)?.fields?.sourceId?.value as string;
  }
  useEffect(() => {
    if (isYoutubeVideo) {
      if (!videoThumbnail) {
        getYoutubeThumbnail(videoId).then((data: youtubeThumbnailProps) => {
          setyoutubeVideoThumbnail(data);
        });
      } else {
        const thumbnailURL = videoThumbnail.value && videoThumbnail.value?.src;
        thumbnailURL && setyoutubeVideoThumbnail({ value: { src: thumbnailURL } });
      }
    }
  }, [isYoutubeVideo, videoId, videoThumbnail]);

  return (
    video && (
      <div className="nextMedia relative w-full grid px-6 sm:mb-10 md:mb-20">
        {isYoutubeVideo ? (
          <BaseVideoModal
            brightcoveVideo={isBrightcoveVideo}
            youtubeVideo={isYoutubeVideo}
            video={video}
            openCtaClassName=""
            openCtaChildren={
              <div className="w-full grid relative aspect-video">
                <div className="before:content-[' '] before:z-10 before:absolute before:bg-imageOverlayBlack before:top-0 before:left-0 before:w-full before:h-full">
                  {youtubeVideoThumbnail ? (
                    <NextImage
                      layout="fill"
                      field={youtubeVideoThumbnail}
                      className="w-full object-cover bg-base-lighter relative flex justify-center"
                    />
                  ) : (
                    <span className="w-full h-full bg-base-lighter relative flex justify-center" />
                  )}
                  <VideoPlayerIcon className="text-[80px] text-white border-[5px] border-playIconBorderBlack bg-playIconBorderBlack rounded-full border-opacity-70" />
                </div>
              </div>
            }
          />
        ) : (
          <BaseVideoModal
            video={video}
            brightcoveVideo={isBrightcoveVideo}
            youtubeVideo={isYoutubeVideo}
            playerId={isBrightcoveVideo ? playerId : undefined}
            openCtaClassName=""
            openCtaChildren={
              <div className="w-full grid  relative aspect-video ">
                <div className="before:content-[' '] before:z-10 before:absolute before:bg-imageOverlayBlack before:top-0 before:left-0 before:w-full before:h-full">
                  {videoThumbnail ? (
                    <NextImage
                      layout="fill"
                      field={videoThumbnail}
                      className="w-full object-cover bg-base-lighter relative flex justify-center"
                    />
                  ) : (
                    <span className="w-full h-full bg-base-lighter relative flex justify-center" />
                  )}
                  <VideoPlayerIcon className="text-[80px] text-white border-[5px] border-playIconBorderBlack bg-playIconBorderBlack rounded-full border-opacity-70" />
                </div>
              </div>
            }
          />
        )}
      </div>
    )
  );
};

export default withDatasourceCheck()<VideoBlockProps>(VideoBlock);
