import {
  GetStaticComponentProps,
  withDatasourceCheck,
  constants,
  GraphQLRequestClient,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect, useState } from 'react';
import {
  ProductCategoryListingProps,
  ProductCategoryListingCategoryFields,
  ProductCategoryListingQueryResponse,
  CategoryFilterOptions,
  RootCategoryToProductsGroups,
  CategoryToProductsGroup,
} from '../../components/category-listing-search/product-category-listing-models';
import config from 'temp/config';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { SearchProductsInCategoryIds } from '../../components/category-listing-search/product-search';
import Link from 'next/link';
import CustomCheckbox from '../../components/category-listing-search/CustomCheckbox';
import { useRouter } from 'next/router';

import ProductCategoryListingProduct from '../../components/category-listing-search/ProductCategoryListingProduct';
import getProductCategoryListingQuery from '../../components/category-listing-search/product-category-listing-query';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { useSSRErrorsHandler } from 'src/opentelemetry/handle-ssr-errors-hook';
import { SsrErrorPropsCreator } from 'src/opentelemetry/elastic-rum-ssrlog-creator';
import { toKeyValuePair } from 'lib/utils/string-format';

const ProductCategoryListing = (props: ProductCategoryListingProps): JSX.Element => {
  useSSRErrorsHandler(props.ssrErrors);
  const { dropdownOptions, rootCategoryToProductsGroups, linkSectionTitle, links } = props;

  const [managedDropdownOptions, setManagedDropdownOptions] =
    useState<CategoryFilterOptions[]>(dropdownOptions);
  const [filteredRootCategoryToProductsGroups, setFilteredRootCategoryToProductsGroups] = useState<
    RootCategoryToProductsGroups[]
  >(rootCategoryToProductsGroups);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<string[]>([]);
  const router = useRouter();
  const isEditing = isEditorActive();

  const onToggleSelectedCategory = (
    categoryId: string,
    checked: boolean,
    childrenIds: string[],
    parentId: string | null
  ): void => {
    if (checked) {
      if (selectedCategoryIds.includes(categoryId)) {
        return;
      }

      setSelectedCategoryIds([...selectedCategoryIds, categoryId, ...childrenIds]);
      return;
    }

    setSelectedCategoryIds(
      selectedCategoryIds.filter(
        (x) => x !== categoryId && !childrenIds.includes(x) && x !== parentId
      )
    );
  };

  const onClickResetAllHandler = (): void => {
    setSelectedCategoryIds([]);
  };

  useEffect(() => {
    if (!isEditing) {
      router.push(
        {
          pathname: window.location.pathname,
          query: { categoryIds: selectedCategoryIds.join('|') },
        },
        undefined,
        { shallow: true }
      );
    }

    const clonedOptions = [...managedDropdownOptions];
    for (let i = 0; i < clonedOptions.length; i++) {
      const option = clonedOptions[i];
      if (selectedCategoryIds.includes(option.id)) {
        option.checked = true;

        for (let j = 0; j < option.children.length; j++) {
          const child = option.children[j];
          child.checked = true;
        }
      } else {
        option.checked = false;

        for (let j = 0; j < option.children.length; j++) {
          const child = option.children[j];
          child.checked = false;
        }
      }

      for (let j = 0; j < option.children.length; j++) {
        const child = option.children[j];
        if (selectedCategoryIds.includes(child.id)) {
          child.checked = true;
        } else {
          child.checked = false;
        }
      }

      const allChildrenChecked = option.children.every((x) => x.checked);
      option.checked = allChildrenChecked;
    }

    setManagedDropdownOptions(clonedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategoryIds]);

  useEffect(() => {
    //iniitalize selected options based on query params
    const { categoryIds } = router.query;
    if (categoryIds == null) {
      return;
    }

    const parsedCategoryIds = categoryIds
      .toString()
      .split('|')
      .filter((x) => x !== '');
    if (parsedCategoryIds.length === 0) {
      return;
    }

    setSelectedCategoryIds(parsedCategoryIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  useEffect(() => {
    const flatCategoryIdsToDisplay: string[] = [];

    for (let i = 0; i < managedDropdownOptions.length; i++) {
      const option = managedDropdownOptions[i];
      if (option.checked) {
        flatCategoryIdsToDisplay.push(...option.categoryIdsToFilter);
      }

      for (let j = 0; j < option.children.length; j++) {
        const child = option.children[j];
        if (child.checked) {
          flatCategoryIdsToDisplay.push(...child.categoryIdsToFilter);
        }
      }
    }

    if (flatCategoryIdsToDisplay.length === 0) {
      setFilteredRootCategoryToProductsGroups(rootCategoryToProductsGroups);
      return;
    }
    const filteredResults = rootCategoryToProductsGroups
      .map((rootCategory) => {
        const filteredCategory = { ...rootCategory };
        filteredCategory.categoryToProductsGroups = rootCategory.categoryToProductsGroups.filter(
          (category) => {
            const hasSelectedCategory = flatCategoryIdsToDisplay.includes(category.categoryId);
            return hasSelectedCategory;
          }
        );
        return filteredCategory;
      })
      .filter((x) => x.categoryToProductsGroups.length > 0);

    setFilteredRootCategoryToProductsGroups(filteredResults);
  }, [managedDropdownOptions, rootCategoryToProductsGroups]);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const onClickToggleHandler = (): void => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const filters = (): JSX.Element => {
    return (
      <>
        <div className="w-full">
          <div className="body-bold bg-white border-y border-base-normal lg:border-none lg:bg-base-lighter px-3 py-2 rounded-s-sm">
            {t('CategoryProductListing_ProductCategory')}
          </div>
          <div className="filters pt-4 px-4 bg-base-lighter lg:bg-white">
            {managedDropdownOptions?.map((option) => {
              return (
                <div className="filter-item pb-8" key={option.id}>
                  <CustomCheckbox
                    label={option.displayName}
                    checked={option.checked}
                    onChange={(checked): void =>
                      onToggleSelectedCategory(
                        option.id,
                        checked,
                        option.children.map((x) => x.id),
                        null
                      )
                    }
                  />
                  {option.children.map((child) => {
                    return (
                      <div className="filter-item relative second-level pl-7 py-2" key={child.id}>
                        <CustomCheckbox
                          label={child.displayName}
                          checked={child.checked}
                          onChange={(checked): void =>
                            onToggleSelectedCategory(child.id, checked, [], option.id)
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>

        <div className="px-3 py-2 bg-white border-t border-base-normal lg:border-none">
          <label className="input-label text-almost-black mt-7">{linkSectionTitle?.value}</label>

          <div className="flex flex-col underline text-left text-base text-primary hover:cursor-pointer">
            {links?.jsonValue?.map((link, idx) => (
              <Link key={idx} href={link.url} legacyBehavior>
                {link.fields?.searchTitle?.value}
              </Link>
            ))}
          </div>
        </div>
      </>
    );
  };

  const leftSection = (): JSX.Element => {
    return (
      <div className="">
        <div className="hidden lg:block w-full lg:w-278">
          <div className="mb-4 flex justify-between">
            <h4>{t('CategoryProductListing_RefineBy')}</h4>
            {selectedCategoryIds.length > 0 && (
              <button
                className="text-center whitespace-nowrap font-bold text-primary text-lg leading-6.75 align-middle rounded focus:outline-none focus-visible:ring-3 ring-primary hover:bg-base-light hover:text-primary-dark hover:cursor-pointer active:bg-base-normal active:text-primary-darker disabled:text-base-normal hover:underline underline-offset-2"
                onClick={(): void => {
                  onClickResetAllHandler();
                }}
              >
                {t('CategoryProductListing_ResetAll')}
              </button>
            )}
          </div>

          <div className="flex flex-col items-start justify-start w-full py-6 px-4 bg-white rounded border border-base-normal box-border">
            {filters()}
          </div>
        </div>
        <div className="block lg:hidden">
          <div>
            <section className="flex justify-center">
              <button
                onClick={onClickToggleHandler}
                className="items-center flex border-2 border-base-darkest p-2 rounded"
              >
                <i className="refine-results-button-icon">
                  <img
                    src="https://cdn.avid.com/avidcom/images/shared/icons/search-refine-icon.svg"
                    alt=""
                  />
                </i>

                <span className="refine-results-button-text">
                  {t('CategoryProductListing_RefineResults')}
                </span>
              </button>
            </section>
          </div>
          <div
            className={`facets facets-layout-wrapper lg:overflow-hidden ${
              mobileMenuOpen ? 'is-open' : ''
            }`}
          >
            <div
              className={`facets-container-backdrop bg-black bottom-0 top-0 right-0 opacity-0 transition-opacity duration-300 w-full fixed pointer-events-none z-50 [&.is-open]:opacity-75 [&.is-open]:z-50 [&.is-open]:pointer-events-auto ${
                mobileMenuOpen ? 'is-open' : ''
              }`}
              onClick={onClickToggleHandler}
            ></div>
            <section
              className={`facets-container bg-white bottom-0 right-0 top-0 max-w-sm w-0 transition-all duration-300  overflow-auto fixed z-60 [&.is-open]:w-11/12 ${
                mobileMenuOpen ? 'is-open' : ''
              }`}
            >
              <header className="facets-header flex justify-between items-center p-4">
                <h4 className="facets-header-title">{t('CategoryProductListing_RefineBy')}</h4>

                <button className="facets-header-close" onClick={onClickToggleHandler}>
                  <i className="facets-header-close-icon">
                    <img
                      src="https://cdn.avid.com/avidcom/images/shared/icons/search-close.svg"
                      alt=""
                    />
                  </i>
                </button>
              </header>{' '}
              <div>{filters()}</div>
              <div className="fixed bottom-0 max-w-sm w-11/12">
                <div className="flex justify-around p-4 border-t border-base-light">
                  <button className="btn-primary" onClick={onClickToggleHandler}>
                    {t('CategoryProductListing_ShowResults')}
                  </button>
                  {selectedCategoryIds.length > 0 && (
                    <button
                      className="text-center whitespace-nowrap font-bold text-primary text-lg leading-6.75 align-middle rounded focus:outline-none focus-visible:ring-3 ring-primary hover:bg-base-light hover:text-primary-dark hover:cursor-pointer active:bg-base-normal active:text-primary-darker disabled:text-base-normal hover:underline underline-offset-2"
                      onClick={(): void => {
                        onClickResetAllHandler();
                      }}
                    >
                      {t('CategoryProductListing_ResetAll')}
                    </button>
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  };
  const labels = props.labels?.value ? toKeyValuePair(props?.labels?.value) : {};
  const t = (key: string): string => {
    return labels[key] || key;
  };
  return (
    <div className="container m-auto px-5">
      <div className="flex flex-col gap-12 lg:gap-8 lg:flex-row">
        {leftSection()}

        <div className="pb-10 lg:pb-20 flex-1">
          <Placeholder name="jss-clp" rendering={props.rendering} />

          <div className="results">
            {filteredRootCategoryToProductsGroups?.map((category) => {
              return (
                <div key={category.categoryId}>
                  <h2 className="text-almost-black leading-9 uppercase mb-10 tracking-widest-plus">
                    {category.displayName}
                  </h2>
                  {category.categoryToProductsGroups.map((group, index) => {
                    return (
                      <div key={group.categoryId}>
                        <section className="mb-10 text-center sm:text-left">
                          <h3>{group.displayName}</h3>

                          <p className="text-almost-black">{group.description}</p>
                        </section>

                        <ul className="grid grid-cols-1 2xl:grid-cols-2 gap-6">
                          {group.products.map((product) => {
                            return (
                              <ProductCategoryListingProduct
                                productResult={product}
                                key={product.id}
                              />
                            );
                          })}
                        </ul>
                        {category.categoryToProductsGroups.length - 1 !== index && (
                          <div className="divier my-10 h-1px w-full bg-almost-black"></div>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
/**
 * Will be called during SSG
 * @param {ComponentRendering} rendering
 * @param {LayoutServiceData} layoutData
 * @param {GetStaticPropsContext} context
 */
export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  if (process.env.JSS_MODE === constants.JSS_MODE.DISCONNECTED) {
    return null;
  }

  if (
    rendering?.dataSource == null ||
    rendering?.dataSource == '' ||
    layoutData?.sitecore?.context?.language == null
  ) {
    return {};
  }

  const ssrErrorLogger = new SsrErrorPropsCreator();

  const language = layoutData.sitecore.context.language;
  const graphQLClient = new GraphQLRequestClient(config.graphQLEndpoint, {
    apiKey: config.sitecoreApiKey,
  });

  const datasourceResults = await graphQLClient.request<ProductCategoryListingQueryResponse>(
    getProductCategoryListingQuery,
    {
      datasource: rendering.dataSource,
      language: language,
    }
  );

  if (!datasourceResults) {
    ssrErrorLogger.logError({
      functionName: 'get datasourceResults; ',
      errorMessage: `Failed to get response; rendering.dataSource: ${JSON.stringify(
        rendering.dataSource
      )}; Langusge: ${language};
      Response: ${JSON.stringify(datasourceResults)}`,
    });
  }

  if (datasourceResults.datasource.categoryFilter.targetItems.length < 1) {
    ssrErrorLogger.logError({
      functionName: 'get datasourceResults; ',
      errorMessage: `Got empty categoryFilter.targetItems; Datasource: ${JSON.stringify(
        rendering.dataSource
      )}; Langusge: ${language};
      Response: ${JSON.stringify(datasourceResults)}`,
    });
  }

  const categoryFilterOptions: CategoryFilterOptions[] = [];
  const rootCategoryToProductsGroups: RootCategoryToProductsGroups[] = [];

  for (let i = 0; i < datasourceResults.datasource.categoryFilter.targetItems.length; i++) {
    const authorSelectedCategory = datasourceResults.datasource.categoryFilter.targetItems[i];

    const topDownAncestors = authorSelectedCategory.ancestors
      .reverse()
      .filter((x) => x.categoryName != null);

    const categoryIdsAssociatedToSelectedCategory = topDownAncestors.map((x) => x.id);
    categoryIdsAssociatedToSelectedCategory.push(authorSelectedCategory.id);
    authorSelectedCategory.children.results.map((levelOneChild) => {
      if (levelOneChild.categoryName != null) {
        categoryIdsAssociatedToSelectedCategory.push(levelOneChild.id);
        levelOneChild.children.results.map((levelTwoChild) => {
          if (levelTwoChild.categoryName != null) {
            categoryIdsAssociatedToSelectedCategory.push(levelTwoChild.id);
          }
        });
      }
    });

    const categoryToProducts: CategoryToProductsGroup[] = [];
    let rootCategory: ProductCategoryListingCategoryFields;

    if (topDownAncestors.length > 0) {
      rootCategory = topDownAncestors[0];

      let level2Category: ProductCategoryListingCategoryFields | null = null;
      const has2ndLevel = topDownAncestors.length > 1;
      if (has2ndLevel) {
        level2Category = topDownAncestors[1];
      } else {
        level2Category = authorSelectedCategory;
      }

      const applicableIds = categoryIdsAssociatedToSelectedCategory.filter(
        (x) => x !== rootCategory.id
      );
      const products = await SearchProductsInCategoryIds(
        applicableIds,
        '{6219196D-1E69-4AC6-8D24-997042832356}'
      );

      if (!products) {
        ssrErrorLogger.logError({
          functionName: 'SearchProductsInCategoryIds; ',
          errorMessage: `topDownAncestors.length > 0;
          Failed to get response; applicableIds: ${applicableIds};
          Response: ${JSON.stringify(products)}`,
        });
      }

      categoryToProducts.push({
        displayName: level2Category.categoryName.value,
        description: level2Category.categoryDescription.value,
        categoryId: level2Category.id,
        products: products,
      });

      categoryFilterOptions.push({
        id: rootCategory.id,
        displayName: rootCategory.categoryName.value,
        categoryIdsToFilter: categoryIdsAssociatedToSelectedCategory,
        checked: false,
        children: [
          {
            id: level2Category.id,
            displayName: level2Category.categoryName.value,
            categoryIdsToFilter: applicableIds,
            children: [],
            checked: false,
          },
        ],
      });
    } else {
      rootCategory = authorSelectedCategory;

      const children: CategoryFilterOptions[] = [];
      for (let j = 0; j < authorSelectedCategory.children.results.length; j++) {
        const secondLevel = authorSelectedCategory.children.results[j];
        const applicableIds = secondLevel.children.results
          .filter((x) => x.categoryName != null)
          .map((x) => x.id);

        applicableIds.push(secondLevel.id);

        const levelThreeCategoryIds = secondLevel.children.results
          .filter((x) => x.categoryName)
          .map((x) => x.id);

        if (levelThreeCategoryIds.length > 0) {
          applicableIds.push(...levelThreeCategoryIds);
        }

        const products = await SearchProductsInCategoryIds(
          applicableIds,
          '{6219196D-1E69-4AC6-8D24-997042832356}'
        );

        if (!products) {
          ssrErrorLogger.logError({
            functionName: 'SearchProductsInCategoryIds; ',
            errorMessage: `topDownAncestors.length == 0;
            Failed to get response; applicableIds: ${applicableIds};
            Response: ${JSON.stringify(products)}`,
          });
        }

        categoryToProducts.push({
          displayName: secondLevel.categoryName.value,
          description: secondLevel.categoryDescription.value,
          categoryId: secondLevel.id,
          products: products,
        });

        children.push({
          id: secondLevel.id,
          displayName: secondLevel.categoryName.value,
          categoryIdsToFilter: applicableIds,
          children: [],
          checked: false,
        });
      }

      categoryFilterOptions.push({
        id: rootCategory.id,
        displayName: rootCategory.categoryName.value,
        categoryIdsToFilter: categoryIdsAssociatedToSelectedCategory,
        children: children,
        checked: false,
      });
    }

    rootCategoryToProductsGroups.push({
      categoryId: rootCategory.id,
      displayName: rootCategory.categoryName.value,
      categoryToProductsGroups: categoryToProducts,
    });
  }

  const groupedDropdownOptions = categoryFilterOptions.reduce((previousValue, currentValue) => {
    if (!previousValue[currentValue.id]) {
      previousValue[currentValue.id] = [];
    }

    previousValue[currentValue.id].push(currentValue);
    return previousValue;
  }, {});

  const finalDropdownOptions: CategoryFilterOptions[] = [];
  for (const key in groupedDropdownOptions) {
    const results = groupedDropdownOptions[key] as CategoryFilterOptions[];
    const dropdownOption: CategoryFilterOptions = {
      id: key,
      displayName: results[0].displayName,
      categoryIdsToFilter: [...new Set(results.flatMap((x) => x.categoryIdsToFilter))],
      children: results.flatMap((x) => x.children),
      checked: results[0].checked,
    };

    finalDropdownOptions.push(dropdownOption);
  }

  const groupedCategoryToProductRoots = rootCategoryToProductsGroups.reduce(
    (previousValue, currentValue) => {
      if (!previousValue[currentValue.categoryId]) {
        previousValue[currentValue.categoryId] = [];
      }

      previousValue[currentValue.categoryId].push(currentValue);
      return previousValue;
    },
    {}
  );
  const finalGroupedCategoryToProductRoots: RootCategoryToProductsGroups[] = [];
  for (const key in groupedCategoryToProductRoots) {
    const results = groupedCategoryToProductRoots[key] as RootCategoryToProductsGroups[];
    const dropdownOption: RootCategoryToProductsGroups = {
      categoryId: key,
      displayName: results[0].displayName,
      categoryToProductsGroups: results.flatMap((x) => x.categoryToProductsGroups),
    };

    finalGroupedCategoryToProductRoots.push(dropdownOption);
  }

  const propsObj = {
    dropdownOptions: finalDropdownOptions,
    rootCategoryToProductsGroups: finalGroupedCategoryToProductRoots,
    linkSectionTitle: datasourceResults.datasource.linkSectionTitle,
    links: datasourceResults.datasource.links,
    labels: datasourceResults?.datasource?.labels,
  };
  return ssrErrorLogger.createProperty('ProductCategoryListing', propsObj);
};

export default withDatasourceCheck()<ProductCategoryListingProps>(ProductCategoryListing);
