import { CartRuleSimplified, CartRuleSitecore } from 'src/renderings/cart/CartSummary';

export const getCartItemsLimits = (sitecoreArray: CartRuleSitecore[]): CartRuleSimplified[] => {
  const limitsList: CartRuleSimplified[] = [];

  sitecoreArray.forEach((item) => {
    const min = item.minCartQuantity.value ? Number(item.minCartQuantity.value) : 1;
    const max = item.maxCartQuantity.value ? Number(item.maxCartQuantity.value) : 99;

    item.product.targetItems.forEach((productItem) => {
      limitsList.push({
        productId: productItem.productId.value,
        min,
        max,
      });
    });
  });
  return limitsList;
};
