import { faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import LocalNavigationContent from './LocalNavigationContent';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { useInView } from 'react-intersection-observer';
import { NavigationGroup } from '../global-navigation/shop/navigation-props';
import ReactDOM from 'react-dom';
import { useI18n } from 'next-localization';

const getClassFromAlignment = (alignment: string): string => {
  if (alignment == null) {
    return '';
  }

  switch (alignment.toLowerCase()) {
    case 'left':
      return 'justify-start';
    case 'center':
      return 'justify-center';
    default:
      return 'justify-start';
  }
};

const LocalNavigationMobile = (props: {
  menuItems: NavigationGroup[];
  pageId: string;
  alignment: string;
}): JSX.Element => {
  const { menuItems, pageId, alignment } = props;
  const [showMenu, setShowMenu] = useState(false);
  const { t } = useI18n();
  const [ref, regularContainerOnScreen] = useInView({
    threshold: 0.25,
  });
  const [stickyContainerRoot, setStickyContainerRoot] = useState<HTMLElement | null>(null);
  const editorActive = isEditorActive();

  useEffect(() => {
    setStickyContainerRoot(document.getElementById('local-navigation-mobile'));
  }, [regularContainerOnScreen]);

  const toggleMenu = (): void => {
    showMenu ? (document.body.style.overflow = 'unset') : (document.body.style.overflow = 'hidden');
    setShowMenu(!showMenu);
  };

  const alignmentClass = getClassFromAlignment(alignment);

  const MobileHeader = (props: { isStickyHeader?: boolean }): JSX.Element => (
    <div
      className={`lg:hidden flex-1 flex w-full py-2 pl-6 pr-3 bg-white border-b border-base-normal ${alignmentClass} ${
        props.isStickyHeader && 'shadow-[0px_4px_16px_0px_rgba(0,0,0,0.12)]'
      }`}
    >
      <button onClick={(): void => toggleMenu()} className="btn-link py-1">
        <span>{t('LocalNaviagtion_MobileNavBarText')}</span>
        <FontAwesomeIcon icon={faChevronRight} className="text-xs pl-4" />
      </button>
    </div>
  );

  const mobileMenu = (
    <div className="flex flex-1 lg:hidden text-almost-black">
      <div ref={ref} className="w-full">
        <MobileHeader />
      </div>
      {showMenu && (
        <div className="fixed z-50 top-0 left-0 w-full h-[100vh] bg-almost-black opacity-50" />
      )}

      <div
        className={`fixed z-50 top-0 left-0 w-full h-[100vh] overflow-hidden ${
          showMenu ? '' : 'pointer-events-none'
        }`}
      >
        <div
          className={`fixed z-50 right-0 top-0 w-11/12 max-w-md bg-white h-[100vh] transition-transform ${
            showMenu ? 'translate-x-0 ' : 'translate-x-full'
          }`}
        >
          <div className="flex justify-between py-4 px-5">
            <h3> {t('LocalNaviagtion_MobileMenuTitle')}</h3>
            <button onClick={(): void => toggleMenu()}>
              <FontAwesomeIcon icon={faXmark} className="text-lg" />
            </button>
          </div>
          <div>
            <nav className="block">
              <LocalNavigationContent
                menuItems={menuItems}
                pageId={pageId}
                LinkClassName="py-3 pl-1"
                IconClassName="py-3 pl-4 pr-1 mr-3"
              />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {!editorActive &&
        stickyContainerRoot != null &&
        !regularContainerOnScreen &&
        ReactDOM.createPortal(<MobileHeader isStickyHeader={true} />, stickyContainerRoot)}
      {mobileMenu}
    </div>
  );
};

export default LocalNavigationMobile;
